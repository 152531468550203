export class Attachment {
	id: number;
	data?: string;
	filename: string;
	type: string;

	constructor(obj: any = {}) {
		this.id = obj.id;
		this.data = obj.data;
		this.filename = obj.filename;
		this.type = obj.type;
	}
}
