export enum SubsidySubtype {
	NONE = 'NONE',
	BEER_EXPERIENCE = 'BEER_EXPERIENCE',
	CULINARY_EXPERIENCE = 'CULINARY_EXPERIENCE',
	DIGITAL_TRANSFORMATION_CONGRESSVENUES = 'DIGITAL_TRANSFORMATION_CONGRESSVENUES',
	FLEMISH_MASTERS = 'FLEMISH_MASTERS',
	INTELLECTUAL_HERITAGE = 'INTELLECTUAL_HERITAGE',
	NATURE = 'NATURE',
	SPORT_AND_BIKING = 'SPORT_AND_BIKING',
}

export const SUBSIDY_SUBTYPE_TRANSLATIONS = {
	[SubsidySubtype.BEER_EXPERIENCE]: 'Bierbeleving',
	[SubsidySubtype.CULINARY_EXPERIENCE]: 'Culinaire belevingen',
	[SubsidySubtype.DIGITAL_TRANSFORMATION_CONGRESSVENUES]: 'Digitale transformatie congresvenues',
	[SubsidySubtype.FLEMISH_MASTERS]: 'Vlaamse Meesters',
	[SubsidySubtype.NATURE]: 'Natuur',
	[SubsidySubtype.INTELLECTUAL_HERITAGE]: 'Intellectueel Erfgoed',
	[SubsidySubtype.SPORT_AND_BIKING]: 'Recreatief en sportief fietsen',
};

export const SUBSIDY_SUBTYPE_DESCRIPTIONS = {
	[SubsidySubtype.BEER_EXPERIENCE]: '',
	[SubsidySubtype.CULINARY_EXPERIENCE]: '',
	[SubsidySubtype.DIGITAL_TRANSFORMATION_CONGRESSVENUES]: '',
	[SubsidySubtype.FLEMISH_MASTERS]: '',
	[SubsidySubtype.NATURE]: '',
	[SubsidySubtype.INTELLECTUAL_HERITAGE]: '',
	[SubsidySubtype.SPORT_AND_BIKING]: '',
};

export const SUBSIDY_SUBTYPE_LOGOS = {
	[SubsidySubtype.BEER_EXPERIENCE]: '/assets/img/icon-beer.png',
	[SubsidySubtype.CULINARY_EXPERIENCE]: '/assets/img/icon-culinary-experience.png',
	[SubsidySubtype.DIGITAL_TRANSFORMATION_CONGRESSVENUES]: '/assets/img/icon-digital-congres.png',
	[SubsidySubtype.FLEMISH_MASTERS]: '/assets/img/icon-flemish-masters.png',
	[SubsidySubtype.NATURE]: '/assets/img/icon-nature.png',
	[SubsidySubtype.INTELLECTUAL_HERITAGE]: '/assets/img/icon-intellectual-heritage.png',
	[SubsidySubtype.SPORT_AND_BIKING]: '/assets/img/icon-sport-and-biking.png',
};
