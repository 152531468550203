import { Component, Input } from '@angular/core';

@Component({
	selector: 'tvcore-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
	@Input()
	brand: string;
	@Input()
	by?: string = 'Toerisme Vlaanderen';
	@Input()
	logoUrl: string;
}
