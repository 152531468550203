export enum Province {
	ANTWERP = 'ANTWERP',
	BRUSSELS = 'BRUSSELS',
	EAST_FLANDERS = 'EAST_FLANDERS',
	FLEMISH_BRABANT = 'FLEMISH_BRABANT',
	LIMBURG = 'LIMBURG',
	WEST_FLANDERS = 'WEST_FLANDERS',
}

export const PROVINCES = [Province.ANTWERP, Province.BRUSSELS, Province.LIMBURG, Province.EAST_FLANDERS, Province.FLEMISH_BRABANT, Province.WEST_FLANDERS];

export const PROVINCE_TRANSLATIONS = {
	[Province.ANTWERP]: 'Antwerpen',
	[Province.BRUSSELS]: 'Brussel',
	[Province.EAST_FLANDERS]: 'Oost-Vlaanderen',
	[Province.FLEMISH_BRABANT]: 'Vlaams Brabant',
	[Province.LIMBURG]: 'Limburg',
	[Province.WEST_FLANDERS]: 'West-Vlaanderen',
};
