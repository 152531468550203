import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../../core/services/user/user.service';
import { User } from '../../core/services/user/user.model';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'tvsub-completed',
	templateUrl: './completed.component.html',
	styleUrls: ['./completed.component.scss'],
})
export class CompletedComponent implements OnInit {
	info: string;
	subsidyText: string;
	user$: Observable<User | null>;

	constructor(private route: ActivatedRoute, private userService: UserService) {}

	ngOnInit() {
		window.scrollTo(0, 0);

		this.info = this.route.snapshot.data['info'];
		this.subsidyText = this.route.snapshot.data['subsidyText'];
		this.user$ = this.userService.user$;
	}
}
