import { Component, Host, Input, Optional } from '@angular/core';
import { InputComponent } from '../input/input.component';
import { DatepickerComponent } from '../datepicker/datepicker.component';
import { SelectComponent } from '../select/select.component';

@Component({
	selector: 'tvcore-form-control-error',
	templateUrl: './form-control-error.component.html',
	styleUrls: ['./form-control-error.component.scss'],
})
export class FormControlErrorComponent {
	@Input()
	name: string;

	constructor(
		@Optional() @Host() private datepickerComponent: DatepickerComponent,
		@Optional() @Host() private inputComponent: InputComponent,
		@Optional() @Host() private selectComponent: SelectComponent
	) {}

	hasError(name: string): boolean {
		return (
			(this.datepickerComponent && this.datepickerComponent.hasError(name)) ||
			(this.inputComponent && this.inputComponent.hasError(name)) ||
			(this.selectComponent && this.selectComponent.hasError(name))
		);
	}
}
