<section class="u-page-section">
	<div class="u-container">
		<div class="c-help-bar">
			<tvcore-tooltip text="Heb je hulp nodig?" size="lg">
				<p>
					Stuur een mailtje naar <a [href]="'mailto:' + email">{{ email }}</a> en we beantwoorden je vraag zo spoedig mogelijk.
				</p>
			</tvcore-tooltip>

			<div (click)="showCloseModal = true">
				<span class="u-text--medium u-text--xlight">Aanvraag afsluiten</span>

				<button title="Sluiten" type="button" class="c-btn-round u-m-left--xxs">
					<span class="icon-close"></span>
				</button>
			</div>
		</div>

		<h1 class="u-heading--xl-bold">Aanvraag van een {{ subsidyText }}</h1>

		<tvsub-stepper [currentStep]="currentStep" [disable]="isDossier" [steps]="steps" (step)="onStepChange($event)"></tvsub-stepper>

		<ng-content></ng-content>
	</div>
</section>

<tvcore-modal
	okButton="Mijn subsidieaanvraag indienen"
	cancelButton="Mijn subsidieaanvraag aanpassen"
	[visible]="showCompleteModal"
	(visibleChange)="onCompleteModal($event)"
	(close)="onComplete($event)"
>
	<p class="u-text--bold">
		Ben je zeker dat je je subsidieaanvraag finaal wil indienen?
		{{ subsidyType === 'hefboomproject' || subsidyType === 'ivv' ? 'Hierna kan je aanvraag niet meer gewijzigd of aangevuld worden.' : '' }}
	</p>
</tvcore-modal>

<tvcore-modal okButton="Afsluiten" cancelButton="Annuleren" [(visible)]="showCloseModal" (close)="onClose($event)">
	<p class="u-text--bold">Er zijn mogelijks nog velden niet opgeslagen. Weet je zeker dat je de aanvraag wilt afsluiten?</p>
</tvcore-modal>
