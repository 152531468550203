import { Subsidy } from '../../../services/subidies/subsidy.model';
import { FinanceInfo } from '../../../services/finance/finance-info.model';

export class TvaSubsidy extends Subsidy {
	financeInfo: FinanceInfo[];
	purposeDescription: string;

	constructor(obj: any = {}) {
		super(obj);

		this.financeInfo = obj.financeInfo ? obj.financeInfo.map((fi: any) => new FinanceInfo(fi)) : [];
		this.purposeDescription = obj.purposeDescription;
	}
}
