<div *ngIf="type !== 'textarea'" class="c-input has-floatable-label is-active" [class.c-input--floating]="isFloating()" [class.is-error]="hasError()">
	<div class="c-input__field-holder">
		<label class="c-input__label is-floatable" [for]="name">{{ label }}</label>
		<input
			*ngIf="type !== 'number' && type !== 'price'"
			[id]="name"
			[type]="type"
			[formControl]="control"
			[attr.autocomplete]="autocomplete === false ? 'none' : null"
			maxlength="200"
			autocomplete="off"
		/>
		<input
			*ngIf="type === 'number'"
			[id]="name"
			type="number"
			[formControl]="control"
			[attr.autocomplete]="autocomplete === false ? 'none' : null"
			(input)="formatNumber()"
			[min]="min"
			[max]="max"
			autocomplete="off"
		/>
    <input
      *ngIf="type === 'price'"
      [id]="name"
      type="text"
      class="c-input--price"
      [formControl]="control"
      [attr.autocomplete]="autocomplete === false ? 'none' : null"
      (input)="formatPrice()"
      [min]="min"
      [max]="max"
      autocomplete="off"
    />
    <span *ngIf="type === 'price' && isFloating()" class="c-input__icon">&euro;</span>
	</div>

	<ng-container *ngIf="innerTemplate" [ngTemplateOutlet]="innerTemplate"></ng-container>
</div>

<div *ngIf="type === 'textarea'" class="c-input is-active" [class.c-input__maxlength]="maxlength" [class.is-error]="hasError()">
	<div class="c-input__field-holder">
		<textarea [class.large]="large" [placeholder]="label" [formControl]="control" [maxlength]="maxlength"></textarea>

		<p *ngIf="maxlength" class="c-input__maxlength-text">
			<ng-container *ngIf="innerTemplate" [ngTemplateOutlet]="innerTemplate" [ngTemplateOutletContext]="{ $implicit: getLength() }"></ng-container>
			<ng-container *ngIf="!innerTemplate">{{ getLength() }} van {{ maxlength }} tekens resterend</ng-container>
		</p>
	</div>
</div>

<ng-content></ng-content>
