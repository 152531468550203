import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubsidyType } from '../../models/subsidy-type.type';
import { Subsidy } from './subsidy.model';
import { LogiesSubsidy } from '../../modules/logies/models/logies-subsidy.model';
import { RelanceSubsidy } from '../../modules/relance/models/relance-subsidy.model';
import { TvaSubsidy } from '../../modules/tva/models/tva-subsidy.model';
import { SubsidyPeriod } from './subsidy-period.model';
import { IvvSubsidy } from '../../modules/ivv/models/ivv-subsidy.model';
import { StoSubsidy } from '../../modules/sto/models/sto-subsidy.model';
import { SkSubsidy } from '../../modules/sk/models/sk-subsidy.model';

@Injectable({
	providedIn: 'root',
})
export class SubsidiesStore {
	unavailable: boolean = false;

	private eligibleOrganizations: { contactId: number; id: string; type: SubsidyType }[] = [];

	private _subsidies$: BehaviorSubject<Subsidy[]> = new BehaviorSubject<Subsidy[]>([]);

	get subsidies$(): Observable<Subsidy[]> {
		return this._subsidies$.asObservable();
	}

	private _selectedSubsidyType: BehaviorSubject<SubsidyType | null> = new BehaviorSubject<SubsidyType | null>(null);

	get selectedSubsidyType(): SubsidyType | null {
		return this._selectedSubsidyType.value;
	}

	private _subsidyPeriods$: BehaviorSubject<SubsidyPeriod[]> = new BehaviorSubject<SubsidyPeriod[]>([]);

	get subsidyPeriods$(): Observable<SubsidyPeriod[]> {
		return this._subsidyPeriods$.asObservable();
	}

	get subsidies(): Subsidy[] {
		return this._subsidies$.value;
	}

	get selectedSubsidyType$(): Observable<SubsidyType | null> {
		return this._selectedSubsidyType.asObservable();
	}

	addSubsidy(subsidy: Subsidy) {
		const subsidies = [...this._subsidies$.value, subsidy];
		this.setSubsidies(subsidies);
	}

	deleteSubsidy(id: number) {
		const subsidies = [...this._subsidies$.value];
		const index = subsidies.findIndex((s: Subsidy) => s.id === id);

		if (index >= 0) {
			subsidies.splice(index, 1);
			this.setSubsidies(subsidies);
		}
	}

	// getSubsidies(): Observable<Subsidy[]> {
	// 	return this._subsidies$.asObservable();
	// }

	// getSubsidiesStatic(): Subsidy[] {
	// 	return this._subsidies$.value;
	// }

	getSubsidy(id: number): Observable<Subsidy | null> {
		return this._subsidies$.asObservable().pipe(
			map((subsidies) => {
				const subsidy = subsidies.find((s: Subsidy) => s.id === id) || null;
				return JSON.parse(JSON.stringify(subsidy));
			})
		);
	}

	getSubsidyStatic(id: number): Subsidy | null {
		const subsidy = this._subsidies$.value.find((s: Subsidy) => s.id === id);

		if (subsidy) {
			return this.createClass(subsidy);
		}

		return null;
	}

	isEligible(type: SubsidyType, id: string | null): { contactId: number; id: string; type: SubsidyType } | null {
		if (!id) {
			return null;
		}

		return this.eligibleOrganizations.find((eo) => eo.type === type && eo.id === id) || null;
	}

	updateSubsidy(id: number, subsidy: Subsidy) {
		const subsidies = [...this._subsidies$.value];
		const index = subsidies.findIndex((s) => s.id === id);

		if (index >= 0) {
			const current = subsidies[index] as any;
			subsidy.baseProduct = current.baseProduct;

			if ((!current.htmlDescriptions || !current.htmlDescriptions.length) && (subsidy as any).htmlDescriptions) {
				(subsidy as any).htmlDescriptions = current.htmlDescriptions;
			}

			subsidies[index] = subsidy;
		} else {
			subsidies.push(subsidy);
		}

		this.setSubsidies(subsidies);
	}

	addEligibleOrganizations(type: SubsidyType, orgs: any[]) {
		this.eligibleOrganizations = [
			...this.eligibleOrganizations,
			...orgs.map((org) => {
				return {
					contactId: org.contactId,
					id: org.organization.companyIdentification,
					type,
				};
			}),
		];
	}

	setSubsidies(subsidies: Subsidy[]) {
		subsidies.sort((a, b) => this.sortFalseFirst(a.submitted, b.submitted));
		this._subsidies$.next(subsidies);
	}

	setPeriods(periods: SubsidyPeriod[]) {
		this._subsidyPeriods$.next(periods);
	}

	setSelectedSubsidyType(type: SubsidyType | 'null' | null) {
		this._selectedSubsidyType.next(type === 'null' ? null : type);
	}

	private createClass(subsidy: any): any {
		switch (subsidy.type) {
			case 'logies':
				return new LogiesSubsidy(subsidy);
			case 'hefboomproject':
				return new RelanceSubsidy(subsidy);
			case 'tva':
				return new TvaSubsidy(subsidy);
			case 'ivv':
				return new IvvSubsidy(subsidy);
			case 'ivv_sto':
				return new StoSubsidy(subsidy);
			case 'ivv_sk':
				return new SkSubsidy(subsidy);
			default:
				return subsidy;
		}
	}

	private sortFalseFirst(a: boolean, b: boolean): number {
		return a === b ? 0 : a ? 1 : -1;
	}
}
