<div
	[class.c-input--floating]="isFloating()"
	[class.is-error]="hasError()"
	class="c-input has-floatable-label has-icon is-active"
>
	<div class="c-input__field-holder">
		<label [for]="name" class="c-input__label is-floatable">{{ label }}</label>
		<dp-date-picker #dpPicker *ngIf="config"
										[config]="config"
										[displayDate]="initialDate"
										[formControl]="control"
										[id]="name"></dp-date-picker>
		<span class="icon-calendar"></span>
	</div>
</div>

<ng-content></ng-content>
