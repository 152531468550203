import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LoadingService {
	private _count: number = 0;
	private _loading$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

	get count(): number {
		return this._count;
	}

	get loading$(): Observable<string | null> {
		return this._loading$.asObservable();
	}

	done() {
		this._count--;

		if (this._count <= 0) {
			this._count = 0;
			this._loading$.next(null);
		}
	}

	load(message?: string) {
		this._count++;

		this._loading$.next(message || 'De gegevens worden ingeladen. Even geduld, alsjeblieft...');
	}

	update(message: string) {
		if (this._count > 0) {
			this._loading$.next(message);

			if (this._count <= 0) {
				this._loading$.next(null);
			}
		}
	}
}
