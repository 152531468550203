import { Subsidy } from '../../../services/subidies/subsidy.model';

export class StoSubsidy extends Subsidy {
	financeRelance: Finance;
	htmlDescriptions?: StoDescriptions | null;
	otherSubsidies: FinanceOther[];

	constructor(obj: any = {}) {
		super(obj);

		this.financeRelance = new Finance(obj.financeRelance);
		this.htmlDescriptions = obj.htmlDescriptions ? new StoDescriptions(obj.htmlDescriptions) : null;
		this.otherSubsidies = obj.otherSubsidies
			? obj.otherSubsidies.map((os: any) => new FinanceOther(os)).sort((a: FinanceOther, b: FinanceOther) => (a.id || 999_999) - (b.id || 999_999))
			: [];
	}
}

class StoDescriptions {
	MISSION: string;
	VISION: string;
	INFORMING_AND_SENSITIZING: string;
	MINIMUM_VACATIONS: string;
	CONTENTS_VACATIONS: string;
	MINIMUM_NEW_PARTICIPANTS: string;
	HELP_TRAVELLING_INDEPENDENTLY: string;
	TRAINING_EMPLOYEES: string;

	constructor(obj: any = {}) {
		this.MISSION = obj.MISSION || '';
		this.VISION = obj.VISION || '';
		this.INFORMING_AND_SENSITIZING = obj.INFORMING_AND_SENSITIZING || '';
		this.MINIMUM_VACATIONS = obj.MINIMUM_VACATIONS || '';
		this.CONTENTS_VACATIONS = obj.CONTENTS_VACATIONS || '';
		this.MINIMUM_NEW_PARTICIPANTS = obj.MINIMUM_NEW_PARTICIPANTS || '';
		this.HELP_TRAVELLING_INDEPENDENTLY = obj.HELP_TRAVELLING_INDEPENDENTLY || '';
		this.TRAINING_EMPLOYEES = obj.TRAINING_EMPLOYEES || '';
	}
}

export class FinanceOther {
	id?: number;
	governmental: boolean;
	subsidyAmount: number;
	subsidyAttachmentId?: number;
	subsidyOrganisation: string;

	constructor(obj: any = {}) {
		this.id = obj.id;
		this.governmental = obj.governmental;
		this.subsidyAmount = obj.subsidyAmount;
		this.subsidyAttachmentId = obj.subsidyAttachmentId;
		this.subsidyOrganisation = obj.subsidyOrganisation;
	}
}

export class Finance {
	id: number;
	detailCosts: FinanceRecord[];
	financeType: FinanceType;

	constructor(obj: any = {}) {
		this.id = obj.id;
		this.financeType = obj.financeType !== 'DEFAULT' ? obj.financeType : null;

		if (obj.detailCosts && obj.detailCosts.length > 0) {
			this.detailCosts = obj.detailCosts.map((d: any) => new FinanceRecord(d));
		} else {
			this.detailCosts = [];
		}
	}
}

export enum FinanceType {
	INCLUSIVE = 'INCLUSIVE',
	TARGETED = 'TARGETED',
}

export class FinanceRecord {
	id?: number;
	amount: number;
	description: string;
	section: FinanceRecordSection;
	type: FinanceRecordType;
	unitsYear1: number;
	unitsYear2: number;
	unitsYear3: number;

	constructor(obj: any = {}) {
		this.id = obj.id;
		this.amount = obj.amount;
		this.description = obj.description;
		this.section = obj.section;
		this.type = obj.type;
		this.unitsYear1 = obj.unitsYear1 || 0;
		this.unitsYear2 = obj.unitsYear2 || 0;
		this.unitsYear3 = obj.unitsYear3 || 0;
	}
}

export enum FinanceRecordSection {
	TRANSPORT = 'TRANSPORT',
	ACCOMMODATION_YOUTH = 'ACCOMMODATION_YOUTH',
	ACCOMMODATION_ADULT = 'ACCOMMODATION_ADULT',
	ENTERTAINMENT_YOUTH = 'ENTERTAINMENT_YOUTH',
	ENTERTAINMENT_ADULT = 'ENTERTAINMENT_ADULT',
	PERSONNEL_STO = 'PERSONNEL_STO',
}

export const FinanceRecordSectionTranslations = {
	[FinanceRecordSection.TRANSPORT]: 'Heen en terugreis',
	[FinanceRecordSection.ACCOMMODATION_YOUTH]: 'Jongerenvakanties',
	[FinanceRecordSection.ACCOMMODATION_ADULT]: 'Gezins- en volwassenenvakanties',
	[FinanceRecordSection.ENTERTAINMENT_YOUTH]: 'Jongerenvakanties',
	[FinanceRecordSection.ENTERTAINMENT_ADULT]: 'Gezins- en volwassenenvakanties',
	[FinanceRecordSection.PERSONNEL_STO]: 'Vakantiecoördinator',
};

export enum FinanceRecordType {
	COST = 'COST',
	INCOME = 'INCOME',
}

export const StoFinancePrice = {
	[FinanceRecordSection.TRANSPORT]: 35,
	[FinanceRecordSection.ACCOMMODATION_YOUTH]: 40,
	[FinanceRecordSection.ACCOMMODATION_ADULT]: 60,
	[FinanceRecordSection.ENTERTAINMENT_YOUTH]: 30,
	[FinanceRecordSection.ENTERTAINMENT_ADULT]: 35,
	[FinanceRecordSection.PERSONNEL_STO]: 40_000,
};

export const StoFinanceInclusivePrice = {
	[FinanceRecordSection.TRANSPORT]: 35,
	[FinanceRecordSection.ACCOMMODATION_YOUTH]: 40,
	[FinanceRecordSection.ACCOMMODATION_ADULT]: 60,
	[FinanceRecordSection.ENTERTAINMENT_YOUTH]: 30,
	[FinanceRecordSection.ENTERTAINMENT_ADULT]: 35,
	[FinanceRecordSection.PERSONNEL_STO]: 40_000,
};
