import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubsidyType } from '../../models/subsidy-type.type';
import { getContext } from '../../utils/context.util';

@Injectable()
export class ContextInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const regex = /^\/api[0-9]{2}\/(logies|hefboomproject|tva|ivv|ivv_sto|ivv_sk)\//;
		const result = regex.exec(req.url);
		const type = result ? result[1] : (null as any);

		if (!type) {
			const params = req.params.set('context', getContext());
			req = req.clone({ params });
			return next.handle(req);
		}

		const context = this.getContextBySubsidyType(type);
		const params = req.params.set('context', context);

		req = req.clone({ params });

		return next.handle(req);
	}

	private getContextBySubsidyType(subsidyType: SubsidyType): string {
		switch (subsidyType) {
			case 'logies':
				return 'SUBSIDY_LOGIES';
			case 'hefboomproject':
				return 'SUBSIDY_HEFBOOM';
			case 'tva':
				return 'SUBSIDY_TVA';
			case 'ivv':
				return 'SUBSIDY_IVV';
			case 'ivv_sto':
				return 'SUBSIDY_STO';
			case 'ivv_sk':
				return 'SUBSIDY_IVV_SK';
		}
	}
}
