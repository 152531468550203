import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertType } from './alert-type.enum';

@Component({
	selector: 'tvcore-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
	@Input()
	action: Function;
	@Input()
	stretch: boolean = false;
	@Input()
	type: AlertType;
	@Output()
	close: EventEmitter<void> = new EventEmitter();

	AlertType = AlertType;

	onAction() {
		if (this.action) {
			this.action();
			this.onClose();
		}
	}

	onClose() {
		this.close.emit();
	}
}
