import { TvaSubsidyApplication } from '../../modules/tva/models/tva-subsidy-application.enum';
import { TvaSubsidyOrganisation } from '../../modules/tva/models/tva-subsidy-organisation.enum';
import { LogiesSubsidyApplication } from '../../modules/logies/models/logies-subsidy-application.enum';

export class FinanceInfo {
	id: number;
	otherSubsidies?: OtherSubsidy[];
	subsidyAmount: number;
	subsidyApplication: LogiesSubsidyApplication | TvaSubsidyApplication;
	totalAmount: number;

	constructor(obj: any = {}) {
		this.id = obj.id;
		this.otherSubsidies = obj.otherSubsidies ? obj.otherSubsidies.map((os: any) => new OtherSubsidy(os)) : [];
		this.subsidyAmount = obj.subsidyAmount;
		this.subsidyApplication = obj.subsidyApplication;
		this.totalAmount = obj.totalAmount;
	}
}

export class OtherSubsidy {
	id: number;
	subsidyAmount: number;
	subsidyOrganisation: TvaSubsidyOrganisation;

	constructor(obj: any = {}) {
		this.id = obj.id;
		this.subsidyAmount = obj.subsidyAmount;
		this.subsidyOrganisation = obj.subsidyOrganisation;
	}
}
