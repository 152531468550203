import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadingService } from '../../services/loading/loading.service';

@Component({
	selector: 'tvcore-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
	loading$: Observable<string | null>;

	constructor(private loadingService: LoadingService) {}

	ngOnInit() {
		this.loading$ = this.loadingService.loading$;
	}
}
