import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'tvcore-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
	@Input()
	disableBackdrop: boolean = false;
	@Input()
	cancelButton?: string;
	@Input()
	empty?: boolean;
	@Input()
	hideCloseButton: boolean = false;
	@Input()
	okButton?: string;
	@Input()
	title: string;
	@Input()
	description?: string;
	@Input()
	visible: boolean;
	@Output()
	close: EventEmitter<boolean> = new EventEmitter();
	@Output()
	visibleChange: EventEmitter<boolean> = new EventEmitter();

	doClose(ok: boolean = false) {
		this.close.emit(ok);
		this.visibleChange.emit(false);
	}

	mayClose() {
		if (!this.disableBackdrop) {
			this.doClose();
		}
	}

	stopProp(event: any) {
		event.stopPropagation();
	}
}
