<main class="u-content">
	<h1 class="u-heading--xl">Stap {{ step + 1 }}: {{ steps[step] }}</h1>

	<hr class="hr-full" />

	<ng-content></ng-content>

	<hr class="hr-full" />

	<div *ngIf="!isDossier; else dossier" class="buttons">
		<button class="c-btn c-btn--secundary" [class.hide-button]="denyPrevious" (click)="doAction(StepContainerAction.PREV)">Vorige</button>
		<button class="c-btn-link" [class.hide-button]="step === 0 && subsidyType !== 'hefboomproject'" (click)="doAction(StepContainerAction.SAVE)">
			Aanvraag opslaan
		</button>
		<button class="c-btn" (click)="doAction(StepContainerAction.NEXT)">Volgende</button>
	</div>

	<ng-template #dossier>
		<div class="buttons">
			<button class="c-btn c-btn--secundary hide-button"></button>
			<button class="c-btn-link hide-button"></button>
			<button class="c-btn" (click)="doAction(StepContainerAction.DOSSIER_NEXT)">Volgende</button>
		</div>
	</ng-template>
</main>
