import { Component, ContentChild, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Address } from '../../core/services/addresses/address.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormComponent } from '../../core/base/form.component';
import { ProjectLocation } from '../../services/project-location/project-location.model';
import { LOCATION_TYPE_TRANSLATIONS, LocationType } from '../../services/project-location/location-type.enum';
import { takeUntil } from 'rxjs/operators';
import { PROVINCE_TRANSLATIONS, PROVINCES } from '../../services/project-location/province.enum';
import { NormalizedAddressComponent } from '../../core/components/normalized-address/normalized-address.component';
import { REGION_TRANSLATIONS, REGIONS } from '../../services/project-location/region.enum';
import { ProjectLocationsService } from '../../services/project-location/project-locations.service';
import { Observable } from 'rxjs';
import { City } from '../../services/project-location/city.model';

@Component({
	selector: 'tvsub-project-location-form',
	templateUrl: './project-location-form.component.html',
	styleUrls: ['./project-location-form.component.scss'],
})
export class ProjectLocationFormComponent extends FormComponent implements OnInit {
	@Input()
	location: ProjectLocation;
	@Input()
	LOCATION_TYPES: LocationType[];
	@ContentChild('overwriteOption')
	overwriteOption: TemplateRef<any>;
	@ViewChild(NormalizedAddressComponent)
	normalizedAddressComponent: NormalizedAddressComponent;

	cities$: Observable<City[]>;

	readonly LocationType = LocationType;
	readonly LOCATION_TYPE_TRANSLATIONS = LOCATION_TYPE_TRANSLATIONS;
	readonly PROVINCES = PROVINCES;
	readonly PROVINCE_TRANSLATIONS = PROVINCE_TRANSLATIONS;
	readonly REGIONS = REGIONS;
	readonly REGION_TRANSLATIONS = REGION_TRANSLATIONS;

	constructor(private fb: UntypedFormBuilder, private locationsService: ProjectLocationsService) {
		super();
	}

	ngOnInit() {
		this.form = this.fb.group({
			id: [this.location.id],
			locationType: [this.location.locationType, Validators.required],
		});

		this.updateValidators(this.location.locationType);

		this.form
			.get('locationType')
			?.valueChanges.pipe(takeUntil(this.destroyed$))
			.subscribe((type) => {
				this.updateValidators(type);
			});

		this.locationsService.initCities();
		this.cities$ = this.locationsService.cities$;
	}

	getData(): any {
		return this.form.value;
	}

	isType(type: LocationType): boolean {
		return this.form.get('locationType')?.value === type;
	}

	isFormValid(valid: boolean = true, dontTriggerInvalidForm: boolean = false): boolean {
		let v = true;

		if (this.normalizedAddressComponent) {
			v = this.normalizedAddressComponent.isValid();
		}

		return super.isFormValid(valid && v, dontTriggerInvalidForm);
	}

	searchCity(search: string, option: City): boolean {
		const name = `${option.postalCode} ${option.city.toLowerCase()}`;
		return name.indexOf(search.toLowerCase()) >= 0;
	}

	selectedCity(option: City): string {
		return `${option.postalCode} ${option.city}`;
	}

	protected onInvalidForm() {}

	protected resetInvalidForm() {}

	private createAddressGroup(address?: Address): UntypedFormGroup {
		return this.fb.group({
			id: [address?.id],
			box: [address?.box],
			cityName: [address?.cityName, Validators.required],
			name: [address?.name],
			number: [address?.number, Validators.required],
			postalCode: [address?.postalCode, Validators.required],
			province: [address?.province],
			street: [address?.street, Validators.required],
		});
	}

	private updateValidators(type: LocationType | '') {
		switch (type) {
			case LocationType.ADDRESS:
				const address = this.createAddressGroup(this.location.address);
				this.form.addControl('address', address);
				this.form.removeControl('city');
				this.form.removeControl('province');
				this.form.removeControl('region');
				break;
			case LocationType.FLEMISH:
				this.form.removeControl('address');
				this.form.removeControl('city');
				this.form.removeControl('province');
				this.form.removeControl('region');
				break;
			case LocationType.PROVINCE:
				this.form.removeControl('address');
				this.form.removeControl('city');
				this.form.addControl('province', this.fb.control(this.location.province, Validators.required));
				this.form.removeControl('region');
				break;
			case LocationType.REGION:
				this.form.removeControl('address');
				this.form.removeControl('city');
				this.form.removeControl('province');
				this.form.addControl('region', this.fb.control(this.location.region, Validators.required));
				break;
			case LocationType.CITY:
				this.form.removeControl('address');
				this.form.addControl('city', this.fb.control(this.location.city, Validators.required));
				this.form.removeControl('province');
				this.form.removeControl('region');
				break;
		}

		this.form.updateValueAndValidity();
	}
}
