import { Contact } from '../../core/services/user/contact.model';
import { RelanceRole } from '../../models/relance-role.model';

export class Beneficiary {
	id: number;
	beneficiary: Contact;
	roleDescription: string;
	roleType: RelanceRole;

	constructor(obj: any = {}) {
		this.id = obj.id;
		this.beneficiary = new Contact(obj.beneficiary);
		this.roleDescription = obj.roleDescription;
		this.roleType = obj.roleType;
	}
}
