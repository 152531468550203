import { Component, ContentChild, Host, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { FormControlComponent } from '../../base/form-control.component';
import { convertPrice } from '../../utils/convert-price.util';

@Component({
	selector: 'tvcore-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
})
export class InputComponent extends FormControlComponent {
	@Input()
	autocomplete: boolean;
	@Input()
	label: string;
	@Input()
	large?: boolean = false;
	@Input()
	min: number;
	@Input()
	max: number;
	@Input()
	maxlength: number;
	@Input()
	type: 'email' | 'number' | 'price' | 'tel' | 'text' | 'textarea' | 'url' = 'text';
	@ContentChild('innerTemplate')
	innerTemplate: any;

	constructor(@Host() controlContainer: ControlContainer) {
		super(controlContainer);
	}

	protected afterInit() {
		if (this.type === 'price') {
			this.formatPrice();
		}
	}

	getLength(): number {
		if (this.control && this.control.value && this.maxlength) {
			return this.maxlength - this.control.value.length;
		}

		return this.maxlength;
	}

	formatNumber() {
		let value: number | null = parseInt(this.control!.value, 10);

		if (isNaN(value)) {
			value = null;
		}

		this.control!.setValue(value);
	}

	formatPrice() {
		let value: number | null = convertPrice(this.control!.value);

		if (isNaN(value || 0)) {
			value = null;
		}

		if (value || value === 0) {
			const n = value.toString(10).replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
			this.control!.setValue(n);
			return;
		}

		this.control!.setValue(null);
	}
}
