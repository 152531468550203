import { ProjectPhase } from '../project-phases/project-phase.model';
import { Attachment } from '../attachments/attachment.model';
import { Contact } from '../../core/services/user/contact.model';
import { Product } from '../../core/services/products/product.model';
import { SubsidyType } from '../../models/subsidy-type.type';

export class Subsidy {
	id: number;
	applicationContact: Contact;
	attachments: Attachment[];
	baseProduct?: Product;
	bic: string;
	contact: Contact | null;
	currentFrontendStep: number;
	description: string;
	iban: string;
	productId: number;
	projectPhases?: ProjectPhase[];
	recoverableVAT: boolean;
	submitted: boolean;
	subsidyApplications: string[];
	type: SubsidyType;
	subtype?: string;

	constructor(obj: any = {}) {
		this.id = obj.id;
		this.applicationContact = new Contact(obj.applicationContact);
		this.attachments = obj.attachments ? obj.attachments.map((a: any) => new Attachment(a)) : [];
		this.baseProduct = new Product(obj.baseProduct);
		this.bic = obj.bic;
		this.contact = new Contact(obj.contact);
		this.currentFrontendStep = +obj.currentFrontendStep || 0;
		this.description = obj.description;
		this.iban = obj.iban;
		this.productId = obj.productId;
		const pp = obj.projectPhases ? obj.projectPhases.map((p: any) => new ProjectPhase(p)) : [];
		this.projectPhases = pp.sort((a: ProjectPhase, b: ProjectPhase) => a.startDate?.getTime() - b.startDate?.getTime());
		this.recoverableVAT = obj.recoverableVAT;
		this.submitted = !!obj.submitted;
		this.subsidyApplications = obj.subsidyApplications || [];
		this.type = obj.type ? obj.type.toLowerCase() : null;

		if (
			this.id &&
			this.currentFrontendStep === 0 &&
			this.type !== 'hefboomproject' &&
			this.type !== 'ivv' &&
			this.type !== 'ivv_sto' &&
			this.type !== 'ivv_sk'
		) {
			this.currentFrontendStep = 1;
		}
	}
}
