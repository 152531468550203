import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { CoreModule } from './core/core.module';
import { CompletedComponent } from './pages/completed/completed.component';
import { ComponentsModule } from './components/components.module';
import { InitComponent } from './pages/init/init.component';

@NgModule({
	declarations: [AppComponent, LoginComponent, LogoutComponent, CompletedComponent, InitComponent],
	imports: [BrowserModule, AppRoutingModule, CoreModule, ComponentsModule],
	bootstrap: [AppComponent],
})
export class AppModule {}
