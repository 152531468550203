import { Component, Host, Input, ViewChild } from '@angular/core';
import { FormControlComponent } from '../../base/form-control.component';
import { DatePickerComponent as DpDatePickerComponent, IDatePickerConfig } from 'ng2-date-picker';
import { ControlContainer } from '@angular/forms';
import * as dayjs from 'dayjs';

@Component({
	selector: 'tvcore-datepicker',
	templateUrl: './datepicker.component.html',
	styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent extends FormControlComponent {
	@Input()
	label: string;
	@Input()
	lang: string = 'nl';
	@Input()
	maxYear?: number;
	@ViewChild('dpPicker')
	component: DpDatePickerComponent;

	config: IDatePickerConfig;
	initialDate: dayjs.Dayjs;

	constructor(
		@Host()
		controlContainer: ControlContainer
	) {
		super(controlContainer);
	}

	protected afterInit() {
		this.initialDate = this.control.value ? dayjs(this.control.value) : dayjs();
		this.config = {
			disableKeypress: true,
			format: 'DD MMM YYYY',
			firstDayOfWeek: 'mo',
			max: this.maxYear ? dayjs(`${this.maxYear}-12-31`) : undefined,
		};
	}
}
