export enum LocationType {
	ADDRESS = 'ADDRESS',
	FLEMISH = 'FLEMISH',
	PROVINCE = 'PROVINCE',
	REGION = 'REGION',
	CITY = 'CITY',
}

export const LOCATION_TYPES_RELANCE = [LocationType.ADDRESS, LocationType.PROVINCE, LocationType.FLEMISH];
export const LOCATION_TYPES_IVV = [LocationType.REGION, LocationType.ADDRESS];

export const LOCATION_TYPE_TRANSLATIONS = {
	[LocationType.ADDRESS]: 'Specifieke locatie',
	[LocationType.FLEMISH]: 'Vlaanderen',
	[LocationType.PROVINCE]: 'Provincie',
	[LocationType.REGION]: 'Regio',
	[LocationType.CITY]: 'Gemeente',
};
