<p [class.u-m-bottom--xxs]="!description" class="u-text--bold u-m-bottom--tiny">
	<span [innerHTML]="title"></span>
	<tvcore-tooltip *ngIf="tooltip" arrow="left-alt" class="u-m-left--tiny">{{ tooltip }}</tvcore-tooltip>
</p>

<p *ngIf="description" [innerHTML]="description" class="u-text--sm u-text--xlight u-m-bottom--xxs"></p>

<div (click)="openModal()" [class.c-input-box--error]="showErrors" class="c-input-box">
	<p *ngIf="placeholder" class="c-input-box__placeholder">{{ placeholder }}</p>
	<p [innerHTML]="control.value" class="c-input-box__text"></p>
	<div class="c-input-box__footer">
		<p>Preview document</p>
	</div>
</div>

<ng-content></ng-content>

<tvcore-wizard-dialog *ngIf="showModal" [fixed]="true" [flexibleHeight]="true">
	<div class="c-wizard__body" role="main">
		<tvcore-dialog-toast></tvcore-dialog-toast>

		<div class="c-wizard__main">
			<div class="c-wizard__content">
				<div class="u-m-bottom--sm">
					<h1 [class.u-m-bottom--tiny]="description" [innerHTML]="title" class="u-heading--sm u-text--bold"></h1>
					<p *ngIf="description" [innerHTML]="description" class="u-text--sm u-text--xlight"></p>
				</div>

				<ckeditor #editor [config]="config" [data]="data" [editor]="Editor"></ckeditor>

				<p class="u-m-top--xxs u-text--sm">Maximaal {{ length }} tekens.</p>

				<p *ngIf="error" class="u-text--error"><span class="icon-warning-circle u-text--xlg"></span> {{ error }}</p>
			</div>

			<div class="c-wizard__navigation" role="navigation">
				<div class="c-button-wrapper c-button-wrapper--space-between">
					<button (click)="closeModal(false)" class="c-btn c-btn--secundary c-btn--full" type="button">Annuleer</button>

					<button (click)="closeModal(true)" class="c-btn c-btn--full" type="button">Opslaan</button>
				</div>
			</div>
		</div>
	</div>
</tvcore-wizard-dialog>
