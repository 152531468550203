<main>
	<section class="p-landing-page" [style.background-image]="'url(' + '/assets/img/landing-page-bg.jpg' + ')'">
		<h1 class="p-landing-page__title">
			Toerisme Vlaanderen
			<span class="u-text--uppercase">Subsidieaanvragen</span>
		</h1>

		<div class="p-landing-page__login anim-reveal-page">
			<ng-container *ngIf="sessionExpired">
				<p class="u-m-bottom">Je sessie is verlopen.</p>

				<a class="c-btn p-landing-page__login-button u-m-bottom no-link" href="/auth"> Terug naar aanmeldscherm </a>

				<hr />
			</ng-container>

			<p class="u-m-bottom">
				<span *ngIf="!sessionExpired">Je bent succesvol afgemeld.</span>
				Wil je je ook afmelden bij de site van Vlaanderen? Klik dan op de knop hieronder.
			</p>

			<a href="https://authenticatie-ti.vlaanderen.be/op/v1/logout" class="c-btn p-landing-page__login-button u-m-bottom no-link">
				Afmelden bij de site van Vlaanderen
			</a>
		</div>
	</section>
</main>
