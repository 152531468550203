import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Toast } from '../../core/services/toast/toast.model';
import { LoadingService } from '../../core/services/loading/loading.service';
import { ToastService } from '../../core/services/toast/toast.service';
import { UserService } from '../../core/services/user/user.service';
import { getContext } from '../../utils/context.util';

@Component({
	selector: 'tvsub-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	loginUrl: string;
	unavailable: boolean;
	toast$: Observable<Toast | null>;

	constructor(
		private loadingService: LoadingService,
		private route: ActivatedRoute,
		private router: Router,
		private toastService: ToastService,
		private userService: UserService
	) {}

	ngOnInit() {
		this.loginUrl = `/acmidm?context=${getContext()}`;
		this.toast$ = this.toastService.toast$;
		this.unavailable = !!this.route.snapshot.data.unavailable;

		if (this.unavailable) {
			this.toastService.clearToast();
		} else {
			this.route.queryParamMap.subscribe((q) => {
				if (q.has('code')) {
					const code = q.get('code');
					this.tryCode(code);
				} else {
					this.tryLogin();
				}
			});
		}

		if (localStorage.getItem('login') === 'true') {
			localStorage.removeItem('login');
			window.location.href = this.loginUrl;
		}

		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 100);
	}

	private tryCode(code: string | null) {
		const devmode = localStorage.getItem('devmode');

		if (!devmode) {
			this.loadingService.load('');
			location.href = `/acmidm?code=${code}&callbackurl=${document.location.origin}&context=${getContext()}`;
		}
	}

	private tryLogin() {
		this.loadingService.load('');

		this.userService
			.initUser()
			.pipe(finalize(() => this.loadingService.done()))
			.subscribe(
				() => this.router.navigate(['/'])
				// Ignore error
			);
	}
}
