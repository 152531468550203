import { Address } from '../addresses/address.model';
import { CompanyType } from './company-type.enum';

export class AllowedOrganization {
	address: Address;
	organization: Organization;
}

export class Organization {
	companyIdentification: string;
	companyType: CompanyType;
	name: string;

	constructor(obj: any = {}) {
		this.companyIdentification = obj.companyIdentification;
		this.companyType = obj.companyType;
		this.name = obj.name;
	}
}
