import { isValidDate } from '../../core/utils/date-checker.util';

export class ProjectPhase {
	id: number;
	description: string;
	endDate: Date;
	startDate: Date;

	constructor(obj: any = {}) {
		this.id = obj.id;
		this.description = obj.description;

		if (obj.startDate && isValidDate(obj.startDate)) {
			this.startDate = new Date(obj.startDate);
		}

		if (obj.startDate && isValidDate(obj.endDate)) {
			this.endDate = new Date(obj.endDate);
		}
	}
}
