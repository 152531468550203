import { Component, ContentChild, Host, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { FormControlComponent } from '../../base/form-control.component';

@Component({
	selector: 'tvcore-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
})
export class SelectComponent extends FormControlComponent {
	@Input()
	label?: string;
	@Input()
	key?: string;
	@Input()
	options: any[];
	@Input()
	placeholder?: string;

	@ContentChild('optionTemplate')
	optionTemplate: any;

	constructor(
		@Host()
		controlContainer: ControlContainer
	) {
		super(controlContainer);
	}

	getValue(option: any): any {
		if (!this.key) {
			return option;
		}

		const parts = this.key.split('.');
		return parts.reduce((obj, k) => (obj ? obj[k] : null), option);
	}
}
