import { Component, Input, OnInit } from '@angular/core';
import { DestroyableComponent } from '../../base/destroyable.component';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Address } from '../../services/addresses/address.model';
import { AddressesService } from '../../services/addresses/addresses.service';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'tvcore-normalized-address',
	templateUrl: './normalized-address.component.html',
	styleUrls: ['./normalized-address.component.scss'],
})
export class NormalizedAddressComponent extends DestroyableComponent implements OnInit {
	@Input()
	extended: boolean;
	@Input()
	group: UntypedFormGroup;
	@Input()
	hideRequired: boolean;
	@Input()
	showErrors: boolean;

	loading: boolean = false;
	shouldSelectError: boolean = false;
	$addresses: Observable<Address[]> | null;

	constructor(private addressesService: AddressesService) {
		super();
	}

	ngOnInit() {
		this.onChange('street');
		this.onChange('postalCode');
		this.onChange('cityName');
	}

	selectAddress(address: Address) {
		this.shouldSelectError = false;

		this.group.patchValue(
			{
				street: address.street,
				postalCode: address.postalCode,
				cityName: address.cityName,
			},
			{
				emitEvent: false,
			}
		);

		this.loading = false;
		this.$addresses = null;
	}

	isValid(): boolean {
		if (!this.$addresses) {
			return true;
		}

		this.shouldSelectError = true;

		return false;
	}

	private onChange(name: string) {
		this.group
			.get(name)!
			.valueChanges.pipe(debounceTime(300), takeUntil(this.destroyed$))
			.subscribe(() => {
				this.loading = true;
				this.$addresses = this.addressesService.findAddress(this.group.value);
			});
	}
}
