import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DestroyableComponent } from '../../core/base/destroyable.component';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Subsidy } from '../../services/subidies/subsidy.model';
import { SubsidyDossier } from '../../services/subsidy-dossiers/subsidy-dossier.model';
import { Attachment } from '../../services/attachments/attachment.model';
import { takeUntil } from 'rxjs/operators';
import { ToastService } from '../../core/services/toast/toast.service';
import { SubsidyType } from '../../models/subsidy-type.type';

@Component({
	selector: 'tvsub-attachments-card',
	templateUrl: './attachments-card.component.html',
	styleUrls: ['./attachments-card.component.scss'],
})
export class AttachmentsCardComponent extends DestroyableComponent implements OnInit {
	@Input()
	api: string = 'subsidy';
	@Input()
	disableSkip: boolean = false;
	@Input()
	form?: UntypedFormGroup;
	@Input()
	errorText?: string;
	@Input()
	insideDialog: boolean = false;
	@Input()
	showError: boolean;
	@Input()
	skipText?: string;
	@Input()
	skipTextAlt?: string;
	@Input()
	subsidy: Subsidy | SubsidyDossier;
	@Input()
	title: string;
	@Input()
	type: string;
	@Output()
	delete: EventEmitter<Attachment> = new EventEmitter();
	@Output()
	upload: EventEmitter<{
		files: FileList;
		type: string;
	}> = new EventEmitter();
	@ViewChild('uploadButton')
	uploadButton: ElementRef;

	viewAll: boolean = false;

	constructor(private toastService: ToastService) {
		super();
	}

	get subsidyType(): SubsidyType {
		return this.subsidy.type;
	}

	ngOnInit() {
		if (this.form) {
			const control = this.form.get(this.type);
			const altControl = this.form.get(`${this.type}Alt`);

			if (control && altControl) {
				this.flip(control, altControl);
				this.flip(altControl, control);
			}
		}
	}

	deleteAttachment(a: Attachment) {
		this.delete.emit(a);
	}

	handleFileInput(files: FileList) {
		if (files && files.length) {
			for (let i = 0; i < files.length; i++) {
				const file = files.item(i);
				const exists = !!this.subsidy.attachments.find((a) => a.filename === file!.name);

				if (exists) {
					this.toastService.error(`Er bestaat al reeds een bewijsstuk: ${file!.name}`, this.insideDialog);
					return;
				}
			}

			this.upload.emit({
				files,
				type: this.type,
			});
		}
	}

	selectFile() {
		this.uploadButton.nativeElement.click();
	}

	private flip(a: AbstractControl, b: AbstractControl) {
		a.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
			if (value) {
				b.setValue(false);
			}
		});
	}
}
