import { Subsidy } from '../../../services/subidies/subsidy.model';
import { Contact } from '../../../core/services/user/contact.model';
import { FinanceDeminimi } from '../../../models/finance-deminimi.model';
import { TripleAnwer } from '../../../models/triple-answer.enum';
import { RelanceRole } from '../../../models/relance-role.model';
import { FinanceRelance } from '../../../services/finance-relance/finance-relance.model';
import { ProjectLocation } from '../../../services/project-location/project-location.model';

export class IvvSubsidy extends Subsidy {
	applicationContactRelanceRole?: RelanceRole;
	htmlDescriptions?: IvvDescriptions | null;
	financeDeminimis?: FinanceDeminimi[];
	financeRelance: FinanceRelance;
	projectLocations?: ProjectLocation[];
	projectTitle: string;
	receivedDeminimis: TripleAnwer;
	responsibleForOrganizationContact: Contact;

	constructor(obj: any = {}) {
		super(obj);

		this.applicationContactRelanceRole = obj.applicationContactRelanceRole;
		this.htmlDescriptions = obj.htmlDescriptions ? new IvvDescriptions(obj.htmlDescriptions) : null;
		this.financeDeminimis = obj.financeDeminimis ? obj.financeDeminimis.map((fd: any) => new FinanceDeminimi(fd)) : [];
		this.financeRelance = new FinanceRelance(obj.financeRelance);
		this.projectLocations = obj.projectLocations ? obj.projectLocations.map((pl: any) => new ProjectLocation(pl)) : [new ProjectLocation()];
		this.projectTitle = obj.projectTitle;
		this.receivedDeminimis = obj.receivedDeminimis;
		this.responsibleForOrganizationContact = new Contact(obj.responsibleForOrganizationContact);
		this.subtype = obj.subtype;
	}
}

class IvvDescriptions {
	SPECIFIC_DEPARTMENT_OR_UNIT: string;
	TARGET_AUDIENCE_AND_BARRIERS: string;
	CREATING_REGIONAL_PROGRAM_WITH_LOCAL_ASSETS: string;
	INVOLVEMENT_PARTICIPANTS_PROGRAM_CREATION: string;
	EXAMPLES_MICRO_ADVENTURE: string;
	ESTIMATION_PARTICIPANTS_MICRO_ADVENTURE: string;

	constructor(obj: any = {}) {
		this.SPECIFIC_DEPARTMENT_OR_UNIT = obj.SPECIFIC_DEPARTMENT_OR_UNIT || '';
		this.TARGET_AUDIENCE_AND_BARRIERS = obj.TARGET_AUDIENCE_AND_BARRIERS || '';
		this.CREATING_REGIONAL_PROGRAM_WITH_LOCAL_ASSETS = obj.CREATING_REGIONAL_PROGRAM_WITH_LOCAL_ASSETS || '';
		this.INVOLVEMENT_PARTICIPANTS_PROGRAM_CREATION = obj.INVOLVEMENT_PARTICIPANTS_PROGRAM_CREATION || '';
		this.EXAMPLES_MICRO_ADVENTURE = obj.EXAMPLES_MICRO_ADVENTURE || '';
		this.ESTIMATION_PARTICIPANTS_MICRO_ADVENTURE = obj.ESTIMATION_PARTICIPANTS_MICRO_ADVENTURE || '';
	}
}
