import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '../services/user/user.service';
import { User } from '../services/user/user.model';
import { catchError, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
	constructor(private router: Router, private userService: UserService) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		const user = this.userService.user;

		if (!user) {
			return this.userService.initUser().pipe(
				map((u) => this.checkUser(u)),
				catchError(() => {
					this.redirectToLogin();
					return of(false);
				})
			);
		}

		return true;
	}

	private checkUser(user: User): boolean {
		if (!user) {
			this.redirectToLogin();
			return false;
		}

		return true;
	}

	private redirectToLogin() {
		this.router.navigate(['/', 'auth']);
	}
}
