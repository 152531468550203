export function getDate(date: string): Date | null {
	if (isValidDate(date)) {
		return new Date(date);
	}

	return null;
}

export function isValidDate(date: string): boolean {
	const d = new Date(date);
	return !isNaN(d.getTime()) && d.getFullYear() >= 2000;
}
