import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Toast } from './toast.model';
import { ToastType } from './toast-type.enum';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	private _dialogToast$: BehaviorSubject<Toast | null> = new BehaviorSubject<Toast | null>(null);

	get dialogToast$(): Observable<Toast | null> {
		return this._dialogToast$.asObservable();
	}

	private _toast$: BehaviorSubject<Toast | null> = new BehaviorSubject<Toast | null>(null);

	get toast$(): Observable<Toast | null> {
		return this._toast$.asObservable();
	}

	clearToast(dialog: boolean = false) {
		this.showToast(null, dialog);
	}

	error(message: string, dialog: boolean = false) {
		const toast = {
			force: true,
			message,
			type: ToastType.ERROR,
		};

		this.showToast(toast, dialog);
	}

	formError(dialog: boolean = false) {
		const toast = {
			force: true,
			message: 'Gelieve alle velden correct in te vullen.',
			type: ToastType.WARNING,
		};

		this.showToast(toast, dialog);
	}

	serverError(res: HttpErrorResponse, action: Function | null = null, dialog: boolean = false) {
		const toast = {
			action,
			force: true,
			message: (res.error ? res.error.message : res.message) || res,
			type: ToastType.ERROR,
		};

		if (
			toast.message &&
			toast.message.toLowerCase &&
			(toast.message.toLowerCase().indexOf('could not execute statement') >= 0 ||
				toast.message.toLowerCase().indexOf('could not read json') >= 0 ||
				toast.message.toLowerCase().indexOf('exception') >= 0)
		) {
			console.error(toast.message);
			this.formError(dialog);
			return;
		}

		this.showToast(toast, dialog);
	}

	success(message: string, force: boolean = false, action: Function | null = null, dialog: boolean = false) {
		if (!force && this.checkIfPreviousIsForced(dialog)) {
			return;
		}

		const toast = {
			action,
			force,
			message,
			type: ToastType.SUCCESS,
		};

		this.showToast(toast, dialog);
	}

	warning(message: string, force: boolean = false, action: Function | null = null, dialog: boolean = false) {
		if (!force && this.checkIfPreviousIsForced(dialog)) {
			return;
		}

		const toast = {
			action,
			force,
			message,
			type: ToastType.WARNING,
		};

		this.showToast(toast, dialog);
	}

	private checkIfPreviousIsForced(dialog: boolean = false) {
		const toast = dialog ? this._dialogToast$.value : this._toast$.value;

		if (toast) {
			return toast.force;
		}

		return false;
	}

	private showToast(toast: Toast | null, dialog: boolean = false) {
		if (dialog) {
			this._dialogToast$.next(toast);
		} else {
			this._toast$.next(toast);
		}
	}
}
