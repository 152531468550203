<ng-container *ngIf="visible">
	<div class="c-modal" (click)="mayClose()">
		<div class="c-modal__box" [class.c-modal__box--not-empty]="!empty" (click)="stopProp($event)">
			<div *ngIf="title; else noTitle" class="c-modal__header-extended">
				<div class="txt">
					<h1 class="u-heading--xl">{{ title }}</h1>
					<p *ngIf="description" class="u-text--medium" [innerHTML]="description"></p>
				</div>

				<button *ngIf="!hideCloseButton" class="c-btn-round" (click)="doClose()">
					<span class="icon-close"></span>
				</button>
			</div>

			<ng-template #noTitle>
				<div *ngIf="!empty" class="c-modal__header">
					<button class="c-btn-round" (click)="doClose()">
						<span class="icon-close"></span>
					</button>
				</div>
			</ng-template>

			<ng-content></ng-content>

			<div class="c-modal__buttons">
				<button *ngIf="okButton" class="c-btn" (click)="doClose(true)">{{ okButton }}</button>
				<button *ngIf="cancelButton" class="c-btn c-btn--secundary" (click)="doClose()">{{ cancelButton }}</button>
			</div>
		</div>
	</div>
</ng-container>
