export enum InformationType {
	BED_AND_BREAKFAST = 'BED_AND_BREAKFAST',
	CAMPER_AREA = 'CAMPER_AREA',
	CAMPER_TERRAIN = 'CAMPER_TERRAIN',
	CAMPING = 'CAMPING',
	CAMPING_ACCOMMODATION_PARK = 'CAMPING_ACCOMMODATION_PARK',
	GENERIC_ROOMS = 'GENERIC_ROOMS',
	GENERIC_TERRAIN = 'GENERIC_TERRAIN',
	GUEST_ROOM = 'GUEST_ROOM',
	HOLIDAY_ACCOMMODATION = 'HOLIDAY_ACCOMMODATION',
	HOLIDAY_COTTAGE = 'HOLIDAY_COTTAGE',
	HOLIDAY_CENTRE = 'HOLIDAY_CENTRE',
	HOLIDAY_PARK = 'HOLIDAY_PARK',
	HOSTEL = 'HOSTEL',
	HOTEL = 'HOTEL',
	MINI_CAMPING = 'MINI_CAMPING',
	YOUTH_ACCOMMODATION = 'YOUTH_ACCOMMODATION',
}

export const INFORMATION_TYPE_TRANSLATIONS = {
	BED_AND_BREAKFAST: 'Bed & Breakfast',
	CAMPER_AREA: 'Kampeerautoterrein',
	CAMPER_TERRAIN: 'Camperterrein',
	CAMPING: 'Camping',
	CAMPING_ACCOMMODATION_PARK: 'Verblijfpark',
	GENERIC_ROOMS: 'Kamergerelateerd logies',
	GENERIC_TERRAIN: 'Terreingerelateerd logies',
	GUEST_ROOM: 'Gastenkamer',
	HOLIDAY_ACCOMMODATION: 'Vakantielogies',
	HOLIDAY_COTTAGE: 'Vakantiewoning',
	HOLIDAY_CENTRE: 'Vakantiecentrum',
	HOLIDAY_PARK: 'Vakantiepark',
	HOSTEL: 'Hostel',
	HOTEL: 'Hotel',
	MINI_CAMPING: 'Minicamping',
	YOUTH_ACCOMMODATION: 'Jeugdverblijf',
};
