import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { AuthenticationGuard } from './core/guards/authentication.guard';
import { InitComponent } from './pages/init/init.component';

const routes: Routes = [
	{
		path: 'auth',
		children: [
			{
				path: '',
				component: LoginComponent,
			},
			{
				path: 'logout',
				component: LogoutComponent,
			},
		],
	},
	{
		path: 'unavailable',
		component: LoginComponent,
		data: {
			unavailable: true,
		},
	},
	{
		path: 'aanvragen',
		component: InitComponent,
		data: {
			redirect: 'new',
		},
	},
	{
		path: 'dossiers',
		component: InitComponent,
		data: {
			redirect: 'home',
		},
	},
	{
		path: '',
		canActivate: [AuthenticationGuard],
		loadChildren: () => import('./modules/main/main.module').then((m) => m.MainModule),
	},
	{ path: '**', redirectTo: '/' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
