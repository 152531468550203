<div class="c-product" (click)="doSelect()">
	<div class="c-product__image" [style.background-image]="'url(' + (product.primaryImage?.url || '/assets/img/vector-illustration-city-200.svg') + ')'"></div>

	<div class="c-product__content u-m-left--sm">
		<p class="u-text--bold">{{ product.groupName }}</p>
		<p>{{ INFORMATION_TYPE_TRANSLATIONS[product.informationType] }}</p>
	</div>

	<p class="u-m-right--sm"><span class="radio" [class.selected]="isSelected"></span> {{ text }}</p>
</div>
