<form *ngIf="form" [formGroup]="form" class="u-m-bottom--sm">
	<div class="u-row u-row--sm">
		<tvcore-select [options]="LOCATION_TYPES" [showErrors]="showErrors" class="u-col-xs-4" label="Locatie *"
									 name="locationType">
			<ng-template #optionTemplate let-option>
				<ng-container *ngIf="overwriteOption"
											[ngTemplateOutletContext]="{ $implicit: option, text: LOCATION_TYPE_TRANSLATIONS[option] }"
											[ngTemplateOutlet]="overwriteOption"></ng-container>
				<ng-container *ngIf="!overwriteOption">{{ LOCATION_TYPE_TRANSLATIONS[option] }}</ng-container>
			</ng-template>

			<tvcore-form-control-error name="required">Locatie is verplicht.</tvcore-form-control-error>
		</tvcore-select>
	</div>

	<div *ngIf="isType(LocationType.ADDRESS)" class="u-row u-row--sm">
		<tvcore-normalized-address [group]="form.get('address')" [showErrors]="showErrors"
															 class="u-col-xs-12"></tvcore-normalized-address>
	</div>

	<div *ngIf="isType(LocationType.CITY)" class="u-row u-row--sm">
		<tvcore-search-select [fnSearch]="searchCity" [fnSelectedValue]="selectedCity" [options]="cities$ | async"
													[showErrors]="showErrors"
													class="u-col-xs-4"
													key="id"
													label="Gemeente *" name="city">
			<ng-template #optionTemplate let-option>
				{{ option.postalCode + ' ' + option.city }}
			</ng-template>

			<p *ngIf="showErrors && form.get('city')?.invalid" class="u-text--sm u-text--error u-m-top--tiny">Gemeente is
				verplicht.</p>
		</tvcore-search-select>
	</div>

	<div *ngIf="isType(LocationType.PROVINCE)" class="u-row u-row--sm">
		<tvcore-select [options]="PROVINCES" [showErrors]="showErrors" class="u-col-xs-4" label="Provincie *"
									 name="province">
			<ng-template #optionTemplate let-option>
				{{ PROVINCE_TRANSLATIONS[option] }}
			</ng-template>

			<tvcore-form-control-error name="required">Provincie is verplicht.</tvcore-form-control-error>
		</tvcore-select>
	</div>

	<div *ngIf="isType(LocationType.REGION)" class="u-row u-row--sm">
		<tvcore-select [options]="REGIONS" [showErrors]="showErrors" class="u-col-xs-4" label="Regio *"
									 name="region">
			<ng-template #optionTemplate let-option>
				{{ REGION_TRANSLATIONS[option] }}
			</ng-template>

			<tvcore-form-control-error name="required">Regio is verplicht.</tvcore-form-control-error>
		</tvcore-select>
	</div>
</form>
