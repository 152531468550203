import { InformationType } from './information-type.enum';

export class Product {
	businessProductId: number;
	groupName: string;
	informationType: InformationType;
	primaryImage: any;

	constructor(obj?: any) {
		this.businessProductId = obj ? obj.businessProductId : null;
		this.groupName = obj ? obj.groupName : null;
		this.informationType = obj ? obj.informationType : null;
		this.primaryImage = obj ? obj.primaryImage : null;
	}
}
