import { FinanceRelanceRecord } from './finance-relance-record.model';
import { getDate } from '../../core/utils/date-checker.util';

export class FinanceRelance {
	id: number;
	detailCosts?: FinanceRelanceRecord[];
	detailIncome?: FinanceRelanceRecord[];
	participants: number;
	numberOfNights: number;
	supervisors: number;
	personnel?: Personnel[];

	constructor(obj: any = {}) {
		this.id = obj.id;
		this.participants = obj.participants;
		this.numberOfNights = obj.numberOfNights;
		this.supervisors = obj.supervisors;
		this.personnel = (obj.personnel || []).map((p: any) => new Personnel(p));

		if (obj.detailCosts && obj.detailCosts.length > 0) {
			this.detailCosts = obj.detailCosts.map((d: any) => new FinanceRelanceRecord(d));
		} else {
			this.detailCosts = [];
		}

		if (obj.detailIncome && obj.detailIncome.length > 0) {
			this.detailIncome = obj.detailIncome.map((d: any) => new FinanceRelanceRecord(d));
		} else {
			this.detailIncome = [];
		}
	}
}

export class Personnel {
	id: number;
	personnelAffectation?: number;
	personnelEndDate?: Date | null;
	personnelFunction: string;
	personnelSalaryGross?: number;
	personnelStartDate?: Date | null;

	constructor(obj: any = {}) {
		this.id = obj.id;
		this.personnelAffectation = obj.personnelAffectation;
		this.personnelEndDate = getDate(obj.personnelEndDate);
		this.personnelFunction = obj.personnelFunction;
		this.personnelSalaryGross = obj.personnelSalaryGross;
		this.personnelStartDate = getDate(obj.personnelStartDate);
	}
}
