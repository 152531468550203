<div
	class="c-alert"
	[class.clickable]="action"
	[class.is-success]="type === AlertType.SUCCESS"
	[class.is-warning]="type === AlertType.ERROR || type === AlertType.WARNING"
	(click)="onAction()"
>
	<div class="c-alert__wrapper u-container" [class.u-container--full]="stretch">
		<div class="c-alert__content">
			<p class="c-alert__description">
				<ng-content></ng-content>
			</p>

			<button title="sluiten" type="button" class="c-alert__close" (click)="onClose()"><span>sluiten</span><span class="icon-close"></span></button>
		</div>
	</div>
</div>
