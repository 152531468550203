<div class="c-wizard-2">
	<div class="c-wizard-2__inner" [class.c-wizard-2__inner--fixed]="fixed" [class.c-wizard-2__inner--flexible-height]="flexibleHeight">
		<header *ngIf="title" class="c-wizard__header" role="banner">
			<h1 class="c-wizard__title">{{ title }}</h1>

			<button type="button" class="c-wizard__close" title="annuleren" (click)="onClose()">
				<span class="icon-close"></span>
				<span>annuleren</span>
			</button>
		</header>

		<ng-content></ng-content>
	</div>
</div>
