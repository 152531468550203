import { Component, ContentChild, ElementRef, Host, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlContainer, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { FormControlComponent } from '../../base/form-control.component';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'tvcore-search-select',
	templateUrl: './search-select.component.html',
	styleUrls: ['./search-select.component.scss'],
})
export class SearchSelectComponent extends FormControlComponent implements OnChanges {
	@Input()
	fnSearch: (search: string, option: any) => boolean;
	@Input()
	fnSelectedValue: (option: any) => string;
	@Input()
	label?: string;
	@Input()
	key?: string;
	@Input()
	options: any[];
	@Input()
	placeholder?: string;

	@ContentChild('optionTemplate')
	optionTemplate: any;

	innerForm: FormGroup;
	overlayVisible: boolean = false;
	visibleOptions: any[];

	constructor(
		@Host()
		controlContainer: ControlContainer,
		private elementRef: ElementRef,
		private fb: FormBuilder
	) {
		super(controlContainer);
	}

	get hasValue(): boolean {
		return !!this.innerForm.get('search')?.value;
	}

	get isInvalid(): boolean {
		return (this.showErrors && this.innerForm.get('search')?.invalid) || false;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.options && changes.options.currentValue.length && this.control) {
			const option = this.control.value ? this.options.find((o) => (this.key ? o[this.key] : o) === this.control.value) : null;
			this.selectOption(option);
		}
	}

	@HostListener('document:keydown.escape')
	keyEscape() {
		this.overlayVisible = false;
	}

	@HostListener('document:click', ['$event.target'])
	keyClick(target: any) {
		if (!this.elementRef.nativeElement.contains(target)) {
			this.overlayVisible = false;
		}
	}

	clear() {
		this.selectOption(null);
		this.innerForm.get('search')?.setValue('');
	}

	getValue(option: any): any {
		if (!this.key) {
			return option;
		}

		const parts = this.key.split('.');
		return parts.reduce((obj, k) => (obj ? obj[k] : null), option);
	}

	selectOption(option: any) {
		if (option) {
			this.control.setValue(this.key ? option[this.key] : option);
			this.innerForm.get('search')?.setValue(this.fnSelectedValue(option), { emitEvent: false });
			this.overlayVisible = false;
		} else {
			this.control.setValue(null);
		}
	}

	controlValidatorBasedOn(control: AbstractControl): (innerControl: AbstractControl) => ValidationErrors | null {
		return (innerControl: AbstractControl): ValidationErrors | null => {
			if (control.invalid) {
				return {
					invalid: true,
				};
			}

			return null;
		};
	}

	protected afterInit() {
		this.innerForm = this.fb.group({
			search: ['', this.controlValidatorBasedOn(this.control)],
		});

		this.visibleOptions = this.options;

		this.innerForm
			.get('search')
			?.valueChanges.pipe(debounceTime(300), takeUntil(this.destroyed$))
			.subscribe((value) => {
				this.visibleOptions = this.options.filter((o) => this.fnSearch(value, o));
				this.overlayVisible = true;

				if (this.visibleOptions.length === 0) {
					this.overlayVisible = false;
					this.selectOption(null);
				} else if (this.visibleOptions.length === 1) {
					this.selectOption(this.visibleOptions[0]);
				} else {
					this.selectOption(null);
				}
			});
	}
}
