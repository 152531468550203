import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../../core/services/user/user.model';
import { UserService } from '../../core/services/user/user.service';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../../core/services/loading/loading.service';

@Injectable({
	providedIn: 'root',
})
export class SessionService {
	counter: number = 0;
	timer: any;

	private MINUTES = 20;
	private MAX_LOOPS = 5;

	constructor(private http: HttpClient, private loadingService: LoadingService, private router: Router, private userService: UserService) {}

	start() {
		if (this.timer) {
			clearTimeout(this.timer);
		}

		this.timer = setTimeout(() => {
			if (this.counter >= this.MAX_LOOPS) {
				this.reset();
				this.logout();
			} else {
				this.keepSession();
			}
		}, 1_000 * 60 * this.MINUTES);
	}

	stop() {
		if (this.timer) {
			clearTimeout(this.timer);
		}

		this.reset();
	}

	keepSession() {
		this.counter++;
		this.http.get<User>('/api12/users').subscribe();
	}

	reset() {
		this.counter = 0;
	}

	logout() {
		this.loadingService.load('Sessie verlopen...');
		this.userService
			.logout()
			.pipe(
				finalize(() => {
					this.stop();
					this.loadingService.done();
					this.router.navigate(['/', 'auth', 'logout'], { queryParams: { session: 'expired' } });
				})
			)
			.subscribe();
	}
}
