import { Component, ContentChild, ElementRef, HostListener, Input } from '@angular/core';

@Component({
	selector: 'tvcore-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
	@Input()
	arrow: 'left' | 'left-alt' | 'right' = 'left';
	@Input()
	clickable: boolean = false;
	@Input()
	text?: string;
	@Input()
	size: 'lg' | 'xl';
	@ContentChild('textTemplate')
	textTemplate?: any;

	showBubble: boolean = false;

	constructor(private elementRef: ElementRef) {}

	@HostListener('document:keydown.escape')
	keyEscape() {
		this.showBubble = false;
	}

	@HostListener('document:click', ['$event.target'])
	keyClick(target: any) {
		if (!this.elementRef.nativeElement.contains(target)) {
			this.showBubble = false;
		}
	}

	doShowBubble() {
		if (this.clickable) {
			this.showBubble = true;
		}
	}
}
