<div class="c-input has-icon is-active" [class.has-floatable-label]="label" [class.c-input--floating]="label || isFloating()" [class.is-error]="hasError()">
	<div class="c-input__field-holder">
		<label *ngIf="label" class="c-input__label" [for]="name">{{ label }}</label>

		<select [id]="name" [class.empty-select]="!isFloating()" [formControl]="control">
			<option [value]="''" disabled>{{ placeholder ? placeholder : 'Selecteer' }}</option>

			<option *ngFor="let option of options" [value]="getValue(option)">
				<ng-container *ngIf="optionTemplate" [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{ $implicit: option }"></ng-container>
				<ng-container *ngIf="!optionTemplate">{{ option }}</ng-container>
			</option>
		</select>

		<span class="icon-angle-down"></span>

		<p *ngIf="placeholder && !isFloating()" class="placeholder" [class.placeholder--with-label]="label">{{ placeholder }}</p>
	</div>
</div>

<ng-content></ng-content>
