<footer class="c-footer" role="contentinfo">
	<div class="c-footer__wrapper u-container u-container--wide">
		<div class="c-footer__logo">
			<img [src]="logoUrl" alt="Vlaanderen is toerisme" />
		</div>

		<p class="c-footer__disclaimer">
			<b>{{ brand }} is een officiële website van de Vlaamse overheid.</b>
			Uitgegeven door {{ by }}.
		</p>
	</div>
</footer>
