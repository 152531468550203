<section class="u-page-section">
	<div class="u-container">
		<div class="c-help-bar">
			<a class="c-btn-link u-text--uppercase u-text--medium" [routerLink]="['/', 'help']">Heb je hulp nodig?</a>

			<a [routerLink]="['/']">
				<span class="u-text--medium u-text--xlight">Aanvraag afsluiten</span>

				<button title="Sluiten" type="button" class="c-btn-round u-m-left--xxs">
					<span class="icon-close"></span>
				</button>
			</a>
		</div>

		<h1 class="u-heading--xl-bold u-m-bottom--sm">Aanvraag van een {{ subsidyText }}</h1>

		<main *ngIf="user$ | async as user" class="u-content center">
			<span class="icon-checkmark-circle"></span>

			<h1 class="u-heading--xl-bold u-m-bottom--md">Bedankt {{ user.contact.person.firstName }}</h1>

			<p class="u-m-bottom--sm">
				Uw aanvraag <span class="u-text--bold">{{ subsidyText }}</span> wordt doorgestuurd naar ons dossiersysteem.
			</p>

			<p *ngIf="!info" class="u-m-bottom--md u-width--md u-text--center">
				Indien je na een aantal dagen geen samenvatting van je aanvraag in je mailbox krijgt, neem dan contact op.
			</p>
			<p *ngIf="info" class="u-m-bottom--md u-width--md u-text--center" [innerHTML]="info"></p>

			<a class="c-btn no-link" [routerLink]="['/']">Sluiten</a>
		</main>
	</div>
</section>
