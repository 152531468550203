import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export class DestroyableComponent implements OnDestroy {
	protected destroyed$: Subject<void> = new Subject();

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
