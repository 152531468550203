import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FormComponent } from '../../core/base/form.component';
import { takeUntil } from 'rxjs/operators';
import { SubsidiesStore } from '../../services/subidies/subsidies.store';

@Component({
	selector: 'tvsub-subsidy-type-selector',
	templateUrl: './subsidy-type-selector.component.html',
	styleUrls: ['./subsidy-type-selector.component.scss'],
})
export class SubsidyTypeSelectorComponent extends FormComponent implements OnInit {
	subsidyTypes = [
		{
			type: null,
			name: 'Toon alle subsidieaanvragen',
		},
		{
			type: 'dtc',
			name: 'Digitale Transformatie Congressvenues',
		},
		{
			type: 'ivv',
			name: 'Iedereen Verdient Vakantie',
		},
		{
			type: 'ivv_sto',
			name: 'Sociaal-toeristische organisatie',
		},
		{
			type: 'ivv_sk',
			name: 'Solidaire kostendeling',
		},
		{
			type: 'logies',
			name: 'Logies',
		},
		{
			type: 'hefboomproject',
			name: 'Relance',
		},
		{
			type: 'tva',
			name: 'Toerisme voor Allen',
		},
	];

	constructor(private fb: UntypedFormBuilder, private subsidiesStore: SubsidiesStore) {
		super();
	}

	ngOnInit() {
		const selectedSubsidyType = this.subsidiesStore.selectedSubsidyType;

		this.form = this.fb.group({
			subsidyType: [selectedSubsidyType],
		});

		this.form
			.get('subsidyType')
			?.valueChanges.pipe(takeUntil(this.destroyed$))
			.subscribe((type) => {
				this.subsidiesStore.setSelectedSubsidyType(type);
			});
	}

	protected onInvalidForm() {}

	protected resetInvalidForm() {}
}
