import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'tvcore-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
	@Input()
	control: UntypedFormControl;
	@Input()
	name: string;
}
