import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getContext } from '../../utils/context.util';

@Component({
	selector: 'tvsub-init',
	templateUrl: './init.component.html',
	styleUrls: ['./init.component.scss'],
})
export class InitComponent implements OnInit {
	constructor(private route: ActivatedRoute) {}

	ngOnInit() {
		const kbo = this.route.snapshot.queryParamMap.get('kbo');
		const redirect = this.route.snapshot.data.redirect;

		if (kbo) {
			localStorage.setItem('organization', kbo);
			localStorage.setItem('init', new Date().toISOString());
			localStorage.setItem('initloc', redirect);
		}

		const loginUrl = `/acmidm?context=${getContext()}`;
		document.location.href = loginUrl;
	}
}
