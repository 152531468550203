export function convertPrice(price: string | number | null): number | null {
  if (price) {
    const value = price + '';
    return +(value.replace(/ /g, '')) || 0;
  }

  if (price === 0) {
    return 0;
  }

  return null;
}
