import { Pipe, PipeTransform } from '@angular/core';
import { convertPrice } from '../utils/convert-price.util';

@Pipe({
	name: 'tvcorePrice',
})
export class PricePipe implements PipeTransform {
	transform(price: string | number): string {
		let value: number | null = convertPrice(price);

		if (isNaN(value || 0)) {
			value = null;
		}

		if (value || value === 0) {
			const n = value.toString(10).replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
			return n;
		}

		return '0';
	}
}
