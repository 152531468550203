import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../services/user/user.model';
import { ToastService } from '../../services/toast/toast.service';
import { Observable } from 'rxjs';
import { Toast } from '../../services/toast/toast.model';

@Component({
	selector: 'tvcore-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	@Input()
	brand: string;
	@Input()
	logoUrl: string;
	@Input()
	user: User | null;
	@Output()
	logout: EventEmitter<void> = new EventEmitter();

	showUserMenu: boolean = false;
	toast$: Observable<Toast | null>;

	constructor(private toastService: ToastService) {}

	ngOnInit() {
		this.toast$ = this.toastService.toast$;
	}

	doLogout() {
		this.logout.emit();
	}
}
