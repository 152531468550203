export function getContext(): string {
	const logies = location.host.indexOf('logiessubsidies');
	if (logies >= 0) {
		return 'SUBSIDY_LOGIES';
	}

	const relance = location.host.indexOf('relanceoproepen');
	if (relance >= 0) {
		return 'SUBSIDY_HEFBOOM';
	}

	const ivv = location.host.indexOf('oproepivv');
	if (ivv >= 0) {
		return 'SUBSIDY_IVV';
	}

	// SUBSIDY_STO ?
	// SUBSIDY_IVV_SK ?

	return 'SUBSIDY_TVA';
}
