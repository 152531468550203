import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';

@Component({
	selector: 'tvcore-rich-text-editor',
	templateUrl: './rich-text-editor.component.html',
	styleUrls: ['./rich-text-editor.component.scss'],
})
export class RichTextEditorComponent implements OnInit {
	@ViewChild('editor')
	editorComponent: CKEditorComponent;
	@Input()
	title: string;
	@Input()
	tooltip?: string;
	@Input()
	description?: string;
	@Input()
	placeholder: string;
	@Input()
	control: UntypedFormControl;
	@Input()
	length: number = 10_000;
	@Input()
	showErrors: boolean;

	config: any;
	data: string;
	error: string | null;
	showModal: boolean = false;
	Editor = ClassicEditor;

	ngOnInit() {
		this.config = {
			toolbar: [
				'heading',
				'|',
				'bold',
				'italic',
				'|',
				'numberedList',
				'bulletedList',
				'|',
				'indent',
				'outdent',
				'|',
				'link',
				'insertTable',
				'|',
				'undo',
				'redo',
			],
			placeholder: this.placeholder,
		};
		this.data = this.control.value;
	}

	openModal() {
		this.showModal = true;

		setTimeout(() => {
			this.editorComponent.editorInstance?.editing.view.focus();
		}, 200);
	}

	closeModal(save: boolean) {
		this.error = null;

		if (save) {
			const data = this.editorComponent.editorInstance!.getData();
			const raw = data.replace(/<[^>]*>/g, '');

			if (raw.length > this.length) {
				this.error = `De tekst is te lang (${raw.length} tekens). Beperk je tekst tot ${this.length} tekens.`;
				return;
			}

			this.data = data;
			this.control.setValue(data);
		}

		this.showModal = false;
	}
}
