import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepContainerComponent } from './step-container/step-container.component';
import { AttachmentsCardComponent } from './attachments-card/attachments-card.component';
import { SubsidyWizardContainerComponent } from './subsidy-wizard-container/subsidy-wizard-container.component';
import { StepperComponent } from './stepper/stepper.component';
import { ProductSelectItemComponent } from './product-select-item/product-select-item.component';
import { CoreModule } from '../core/core.module';
import { OnlineStatusComponent } from './online-status/online-status.component';
import { SubsidyTypeSelectorComponent } from './subsidy-type-selector/subsidy-type-selector.component';
import { ProjectLocationFormComponent } from './project-location-form/project-location-form.component';

const components = [
	StepContainerComponent,
	AttachmentsCardComponent,
	SubsidyWizardContainerComponent,
	StepperComponent,
	ProductSelectItemComponent,
	OnlineStatusComponent,
	SubsidyTypeSelectorComponent,
	ProjectLocationFormComponent,
];

@NgModule({
	declarations: [...components],
	imports: [CommonModule, CoreModule],
	exports: [...components],
})
export class ComponentsModule {}
