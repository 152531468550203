import { isValidDate } from '../core/utils/date-checker.util';

export class FinanceDeminimi {
	id: number;
	financier: string;
	grantDate: Date;
	totalAmount: number;

	constructor(obj: any = {}) {
		this.id = obj.id;
		this.financier = obj.financier;
		this.totalAmount = obj.totalAmount;

		if (isValidDate(obj.grantDate)) {
			this.grantDate = new Date(obj.grantDate);
		}
	}
}
