<header class="c-header c-header--fixed c-header--shown" role="banner">
	<div class="c-header__top-bar u-container u-container--wide">
		<a class="c-header__top-bar__link c-header__top-bar__flanders-link" href="http://www.vlaanderen.be/nl" target="_blank" rel="noopener noreferrer"
			>Vlaanderen</a
		>
		<a class="c-header__top-bar__link" rel="noopener noreferrer" href="http://www.toerismevlaanderen.be" target="_blank"
			>Toerisme <strong>Vlaanderen</strong></a
		>
		<div class="c-header__top-bar__filler"></div>
	</div>

	<div class="c-header__bar">
		<div class="u-container">
			<div class="c-header__brand">
				<a class="c-header__logo" [routerLink]="['/']">
					<img [src]="logoUrl" alt="home" />
				</a>

				<p class="c-header__title">{{ brand }}</p>
			</div>

			<div *ngIf="user" class="c-header__user-menu">
				<div *ngIf="user.contact?.person" class="c-user-menu" [class.is-open]="showUserMenu">
					<button class="c-user-menu__button is--active" title="Open gebruikersmenu" (click)="showUserMenu = !showUserMenu">
						<div class="c-badge">
							<span class="icon-user"></span>
						</div>

						<span class="c-user-menu__name">{{ user.contact.person.firstName }} {{ user.contact.person.lastName }}</span>
					</button>

					<div class="c-user-menu__flyout">
						<p class="u-m-bottom--xs">
							<strong>{{ user.contact.person.firstName }} {{ user.contact.person.lastName }}</strong
							><br />
							<ng-container *ngIf="user.contact.email">{{ user.contact.email }}<br /></ng-container>
							<ng-container *ngIf="user.contact.address?.street">
								{{ user.contact.address.street }} {{ user.contact.address.number }} {{ user.contact.address.box }}<br />
							</ng-container>
							<ng-container *ngIf="user.contact.address?.cityName">
								{{ user.contact.address.postalCode }} {{ user.contact.address.cityName }}
							</ng-container>
						</p>

						<ul class="c-user-menu__actions">
							<li>
								<button type="button" title="Uitloggen" class="c-btn-link" (click)="doLogout()">Uitloggen</button>
							</li>
						</ul>
					</div>

					<div class="c-user-menu__overlay" (click)="showUserMenu = false"></div>
				</div>
			</div>
		</div>
	</div>

	<tvcore-toast *ngIf="toast$ | async as toast" [toast]="toast"></tvcore-toast>
</header>
