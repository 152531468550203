import { Component, Input } from '@angular/core';
import { FormControlComponent } from '../../base/form-control.component';

@Component({
	selector: 'tvcore-unit-input',
	templateUrl: './unit-input.component.html',
	styleUrls: ['./unit-input.component.scss'],
})
export class UnitInputComponent extends FormControlComponent {
	@Input()
	min: number = 0;
	@Input()
	max: number = 999999;
	@Input()
	step: number = 1;
	@Input()
	unit: string;

	add() {
		let value = this.control.value;

		value += this.step;
		value = this.convert(value);

		this.control.setValue(value);
	}

	subtract() {
		let value = this.control.value;

		value -= this.step;
		value = this.convert(value);

		this.control.setValue(value);
	}

	validate() {
		const value = this.convert(this.control.value);
		this.control.setValue(value);
	}

	private convert(value: any) {
		if (!value) {
			return this.min;
		}

		let v = Number.parseInt(value, 10);

		if (v > this.max) {
			v = this.max;
		} else if (v < this.min) {
			v = this.min;
		}

		return v;
	}
}
