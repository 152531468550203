<main [class.has-toast]="toast$ | async">
	<tvcore-toast *ngIf="toast$ | async as toast" [toast]="toast"></tvcore-toast>

	<section class="p-landing-page" [style.background-image]="'url(' + '/assets/img/landing-page-bg.jpg' + ')'">
		<h1 class="p-landing-page__title">
			Toerisme Vlaanderen
			<span class="u-text--uppercase">Subsidieaanvragen</span>
		</h1>

		<div class="p-landing-page__login anim-reveal-page">
			<a *ngIf="!unavailable; else unavailableTemp" [href]="loginUrl" class="c-btn p-landing-page__login-button u-m-bottom no-link">
				Aanmelden
				<span>Je wordt eerst omgeleid naar de site van Vlaanderen.</span>
			</a>

			<ng-template #unavailableTemp>
				<p>Het is momenteel niet mogelijk om subsidies aan te vragen.</p>
			</ng-template>
		</div>
	</section>
</main>
