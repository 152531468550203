<ng-container [formGroup]="group">
	<div class="u-row u-row--sm">
		<div *ngIf="extended" class="u-col-xs-12 u-col-sm-4 u-m-top--xxs">
			<span class="u-text--light">Adresgegevens:</span>
		</div>

		<tvcore-input class="u-col-xs-12 u-col-sm-8" [label]="'Straat' + (hideRequired ? '' : ' *')" name="street" [showErrors]="showErrors">
			<ng-template #innerTemplate>
				<ng-container *ngIf="$addresses | async as addresses; else loadingTemp">
					<p class="c-autocomplete__help">Selecteer een suggestie</p>
					<div class="c-autocomplete" [class.c-autocomplete--error]="shouldSelectError">
						<p class="c-autocomplete__divider">Topsuggesties</p>
						<ng-container *ngFor="let address of addresses">
							<div *ngIf="address.street" class="c-autocomplete__option" (click)="selectAddress(address)">
								{{ address.street }}, {{ address.postalCode }} {{ address.cityName }}
							</div>
						</ng-container>

						<p class="c-autocomplete__divider">Steden en gemeenten</p>
						<ng-container *ngFor="let address of addresses">
							<div *ngIf="!address.street" class="c-autocomplete__option" (click)="selectAddress(address)">
								{{ address.postalCode }} {{ address.cityName }}
							</div>
						</ng-container>
					</div>

					<p *ngIf="shouldSelectError" class="u-text--error u-text--sm u-m-top--xxs">Gelieve een correct adres te selecteren.</p>
				</ng-container>

				<ng-template #loadingTemp>
					<ng-container *ngIf="loading">
						<p class="c-autocomplete__help">Selecteer een suggestie</p>
						<div class="c-autocomplete">
							<p class="c-autocomplete__divider">Topsuggesties</p>
							<div class="c-autocomplete__option">Laden ...</div>

							<p class="c-autocomplete__divider">Steden en gemeenten</p>
							<div class="c-autocomplete__option">Laden ...</div>
						</div>
					</ng-container>
				</ng-template>
			</ng-template>

			<tvcore-form-control-error name="required">Straat is een verplicht veld.</tvcore-form-control-error>
		</tvcore-input>

		<tvcore-input
			class="u-col-xs-12"
			[class.u-col-sm-2]="!extended"
			[class.u-col-sm-3]="extended"
			[class.u-col-sm-offset-4]="extended"
			[label]="'Huisnummer' + (hideRequired ? '' : ' *')"
			name="number"
			type="number"
			[forceErrors]="true"
			[showErrors]="showErrors"
		>
			<tvcore-form-control-error name="required">Huisnummer is een verplicht veld.</tvcore-form-control-error>
		</tvcore-input>

		<tvcore-input class="u-col-xs-12" [class.u-col-sm-2]="!extended" [class.u-col-sm-3]="extended" label="Bus" name="box" type="text"> </tvcore-input>
	</div>

	<div class="u-row u-row--sm">
		<tvcore-input
			class="u-col-xs-12"
			[class.u-col-sm-2]="!extended"
			[class.u-col-sm-3]="extended"
			[class.u-col-sm-offset-4]="extended"
			[label]="'Postcode' + (hideRequired ? '' : ' *')"
			name="postalCode"
			type="number"
			[forceErrors]="true"
			[showErrors]="showErrors"
		>
			<tvcore-form-control-error name="required">Postcode is een verplicht veld.</tvcore-form-control-error>
		</tvcore-input>

		<tvcore-input
			class="u-col-xs-12"
			[class.u-col-sm-6]="!extended"
			[class.u-col-sm-5]="extended"
			[label]="'Gemeente' + (hideRequired ? '' : ' *')"
			name="cityName"
			[showErrors]="showErrors"
		>
			<tvcore-form-control-error name="required">Gemeente is een verplicht veld.</tvcore-form-control-error>
		</tvcore-input>
	</div>
</ng-container>
