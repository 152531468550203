import { Component, OnInit } from '@angular/core';
import { DestroyableComponent } from '../../core/base/destroyable.component';
import { Observable } from 'rxjs';
import { OnlineService } from '../../core/services/online/online.service';

@Component({
	selector: 'tvsub-online-status',
	templateUrl: './online-status.component.html',
	styleUrls: ['./online-status.component.scss'],
})
export class OnlineStatusComponent extends DestroyableComponent implements OnInit {
	online$: Observable<boolean>;

	constructor(private onlineService: OnlineService) {
		super();
	}

	ngOnInit() {
		this.online$ = this.onlineService.checkOnline(this.destroyed$);
	}
}
