export enum Region {
	ANTWERPSE_KEMPEN = 'ANTWERPSE_KEMPEN',
	ANTWERPEN_MECHELEN = 'ANTWERPEN_MECHELEN',
	BRUGSE_OMMELAND = 'BRUGSE_OMMELAND',
	GROENE_GORDEL = 'GROENE_GORDEL',
	HAGELAND = 'HAGELAND',
	HASPENGOUW = 'HASPENGOUW',
	KUST = 'KUST',
	LEIE_STREEK = 'LEIE_STREEK',
	LIMBURGSE_KEMPEN = 'LIMBURGSE_KEMPEN',
	MAASLAND = 'MAASLAND',
	MEETJESLAND = 'MEETJESLAND',
	SCHELDELAND = 'SCHELDELAND',
	VLAAMSE_ARDENNEN = 'VLAAMSE_ARDENNEN',
	VOERSTREEK = 'VOERSTREEK',
	WAASLAND = 'WAASLAND',
	WESTHOEK = 'WESTHOEK',
}

export const REGIONS: Region[] = [
	Region.ANTWERPSE_KEMPEN,
	Region.ANTWERPEN_MECHELEN,
	Region.BRUGSE_OMMELAND,
	Region.GROENE_GORDEL,
	Region.HAGELAND,
	Region.HASPENGOUW,
	Region.KUST,
	Region.LEIE_STREEK,
	Region.LIMBURGSE_KEMPEN,
	Region.MAASLAND,
	Region.MEETJESLAND,
	Region.SCHELDELAND,
	Region.VLAAMSE_ARDENNEN,
	Region.VOERSTREEK,
	Region.WAASLAND,
	Region.WESTHOEK,
];

export const REGION_TRANSLATIONS = {
	[Region.ANTWERPSE_KEMPEN]: 'Antwerpse Kempen',
	[Region.ANTWERPEN_MECHELEN]: 'Antwerpen-Mechelen',
	[Region.BRUGSE_OMMELAND]: 'Brugse Ommeland',
	[Region.GROENE_GORDEL]: 'Groene Gordel',
	[Region.HAGELAND]: 'Hageland',
	[Region.HASPENGOUW]: 'Haspengouw',
	[Region.KUST]: 'Kust',
	[Region.LEIE_STREEK]: 'Leie streek',
	[Region.LIMBURGSE_KEMPEN]: 'Limburgse Kempen',
	[Region.MAASLAND]: 'Maasland',
	[Region.MEETJESLAND]: 'Meetjesland',
	[Region.SCHELDELAND]: 'Scheldeland',
	[Region.VLAAMSE_ARDENNEN]: 'Vlaamse Ardennen',
	[Region.VOERSTREEK]: 'Voerstreek',
	[Region.WAASLAND]: 'Waasland',
	[Region.WESTHOEK]: 'Westhoek',
};
