import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'tvsub-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
	sessionExpired: boolean = false;

	constructor(private route: ActivatedRoute) {}

	ngOnInit() {
		this.sessionExpired = this.route.snapshot.queryParamMap.get('session') === 'expired';
	}
}
