import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StepContainerAction } from './step-container-action.enum';
import { ToastService } from '../../core/services/toast/toast.service';
import { SubsidyType } from '../../models/subsidy-type.type';

@Component({
	selector: 'tvsub-step-container',
	templateUrl: './step-container.component.html',
	styleUrls: ['./step-container.component.scss'],
})
export class StepContainerComponent implements OnInit {
	@Input()
	denyPrevious: boolean = false;
	@Input()
	isDossier: boolean = false;
	@Input()
	step: number;
	@Input()
	steps: string[];
	@Input()
	subsidyType: SubsidyType;
	@Output()
	action: EventEmitter<StepContainerAction> = new EventEmitter();

	StepContainerAction = StepContainerAction;

	constructor(private toastsService: ToastService) {}

	ngOnInit() {
		window.scrollTo(0, 0);
		this.toastsService.clearToast();
	}

	doAction(action: StepContainerAction) {
		this.action.emit(action);
	}
}
