import { Directive, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';
import { DestroyableComponent } from './destroyable.component';

@Directive()
export class FormControlComponent extends DestroyableComponent implements OnInit {
	@Input()
	forceErrors: boolean = false;
	@Input()
	name: string;
	@Input()
	showErrors: boolean = false;

	control: AbstractControl | any | null;

	constructor(private controlContainer: ControlContainer) {
		super();
	}

	ngOnInit() {
		this.control = this.controlContainer.control!.get(this.name);
		this.afterInit();
	}

	isFloating(): boolean {
		return !!this.control && this.control.value !== null && this.control.value !== '';
	}

	hasError(name?: string): boolean {
		if (this.control) {
			const errors = this.control.errors;

			if (errors && (this.showErrors || (this.forceErrors && this.control.dirty))) {
				if (!name) {
					return true;
				}

				return !!errors[name];
			}
		}

		return false;
	}

	protected afterInit() {}
}
