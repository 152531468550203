<div (click)="doShowBubble()" [class.c-tooltip--clickable]="clickable" class="c-tooltip">
	<span *ngIf="text" class="u-text--uppercase u-text--medium u-text--info">
		{{ text }}
	</span>
	<ng-container *ngIf="textTemplate" [ngTemplateOutlet]="textTemplate"></ng-container>

	<ng-container *ngIf="!text && !textTemplate">
		<div class="info-icon">
			?
		</div>
	</ng-container>

	<div
		[class.c-tooltip__text-bubble--arrow-left-alt]="arrow === 'left-alt'"
		[class.c-tooltip__text-bubble--arrow-right]="arrow === 'right'"
		[class.c-tooltip__text-bubble--visible]="showBubble"
		[class.large]="size === 'lg'"
		[class.x-large]="size === 'xl'"
		class="c-tooltip__text-bubble"
	>
		<div class="c-text-bubble">
			<ng-content></ng-content>
		</div>
	</div>
</div>
