import { Subsidy } from '../../../services/subidies/subsidy.model';
import { ApplicationContactRole } from '../../../models/application-contact-role.enum';
import { FinanceDeminimi } from '../../../models/finance-deminimi.model';
import { TripleAnwer } from '../../../models/triple-answer.enum';
import { Contact } from '../../../core/services/user/contact.model';
import { FinanceInfo } from '../../../services/finance/finance-info.model';
import { RenovationCapacityType } from './renovation-capacity-type.enum';
import { FinanceRelance } from '../../../services/finance-relance/finance-relance.model';

export class LogiesSubsidy extends Subsidy {
	applicationContactRole: ApplicationContactRole;
	childFriendlyBabyFriendly: boolean;
	childFriendlyFamilyFriendly: boolean;
	childFriendlyInspirationDay: boolean;
	childFriendlyPlay: boolean;
	childFriendlyWebsite: boolean;
	financeDeminimis?: FinanceDeminimi[];
	financeInfo?: FinanceInfo[];
	receivedDeminimis: TripleAnwer;
	renovationCapacityType: RenovationCapacityType;
	responsibleForOrganizationContact: Contact | null;

	financeRelance: FinanceRelance;

	constructor(obj: any = {}) {
		super(obj);

		this.applicationContactRole = obj.applicationContactRole;
		this.childFriendlyBabyFriendly = obj.childFriendlyBabyFriendly;
		this.childFriendlyFamilyFriendly = obj.childFriendlyFamilyFriendly;
		this.childFriendlyInspirationDay = obj.childFriendlyInspirationDay;
		this.childFriendlyPlay = obj.childFriendlyPlay;
		this.childFriendlyWebsite = obj.childFriendlyWebsite;
		this.financeDeminimis = obj.financeDeminimis ? obj.financeDeminimis.map((fd: any) => new FinanceDeminimi(fd)) : [];
		this.financeInfo = obj.financeInfo ? obj.financeInfo.map((fi: any) => new FinanceInfo(fi)) : [];
		this.receivedDeminimis = obj.receivedDeminimis;
		this.renovationCapacityType = obj.renovationCapacityType !== RenovationCapacityType.NA ? obj.renovationCapacityType : null;
		this.responsibleForOrganizationContact = obj.responsibleForOrganizationContact ? new Contact(obj.responsibleForOrganizationContact) : null;

		this.financeRelance = new FinanceRelance(obj.financeRelance);
	}
}
