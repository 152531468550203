import { Address } from '../addresses/address.model';
import { ContactTitle } from './contact-title.enum';
import { Organization } from './organization.model';

export class Contact {
	address: Address;
	contactId?: number;
	contactType?: string;
	email: string;
	fax?: string;
	organization: Organization;
	person: UserContactPerson;
	telephoneNumber1: string;
	telephoneNumber2: string;
	telephoneNumber3: string;
	uitId?: string;
	website: string;

	constructor(obj: any = {}) {
		this.address = new Address(obj.address);
		this.contactId = obj.contactId;
		this.contactType = obj.contactType;
		this.email = obj.email;
		this.fax = obj.fax;
		this.telephoneNumber1 = obj.telephoneNumber1;
		this.telephoneNumber2 = obj.telephoneNumber2;
		this.telephoneNumber3 = obj.telephoneNumber3;
		this.uitId = obj.uitId;
		this.website = obj.website;

		this.organization = new Organization(obj.organization);

		this.person = new UserContactPerson(obj.person);
	}
}

class UserContactPerson {
	contactTitle: ContactTitle;
	firstName: string;
	functionName: string;
	lastName: string;
	nationalRegistryNumber: string;
	shareWithPartners: boolean;

	constructor(obj: any = {}) {
		this.contactTitle = obj.contactTitle;
		this.firstName = obj.firstName;
		this.functionName = obj.functionName;
		this.lastName = obj.lastName;
		this.nationalRegistryNumber = obj.nationalRegistryNumber;
		this.shareWithPartners = obj.shareWithPartners;
	}
}
