import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Toast } from '../../services/toast/toast.model';
import { ToastService } from '../../services/toast/toast.service';

@Component({
	selector: 'tvcore-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit, OnDestroy {
	@Input()
	toast: Toast;

	constructor(private toastService: ToastService) {}

	ngOnInit() {
		document.body.classList.add('has-toast');
	}

	clearToast() {
		this.toastService.clearToast();
	}

	ngOnDestroy() {
		document.body.classList.remove('has-toast');
	}
}
