import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubsidyType } from '../../models/subsidy-type.type';

@Component({
	selector: 'tvsub-subsidy-wizard-container',
	templateUrl: './subsidy-wizard-container.component.html',
	styleUrls: ['./subsidy-wizard-container.component.scss'],
})
export class SubsidyWizardContainerComponent implements OnInit {
	@Input()
	currentStep: number;
	@Input()
	isDossier: boolean = false;
	@Input()
	showCompleteModal: boolean = false;
	@Input()
	steps: string[];
	@Input()
	subsidyText: string;
	@Input()
	subsidyType?: SubsidyType;
	@Output()
	close: EventEmitter<void> = new EventEmitter<void>();
	@Output()
	complete: EventEmitter<void> = new EventEmitter<void>();
	@Output()
	currentStepChange: EventEmitter<number> = new EventEmitter();
	@Output()
	showCompleteModalChange: EventEmitter<boolean> = new EventEmitter();

	email: string;
	showCloseModal: boolean = false;

	ngOnInit() {
		switch (this.subsidyType) {
			case 'hefboomproject':
				this.email = 'relance@toerismevlaanderen.be';
				break;
			case 'logies':
				this.email = 'logiessubsidie@toerismevlaanderen.be';
				break;
			case 'tva':
				this.email = 'toerismevoorallen@toerismevlaanderen.be';
				break;
			case 'ivv':
				this.email = 'oproepivv@toerismevlaanderen.be';
				break;
			case 'ivv_sto':
				this.email = 'sto@iedereenverdientvakantie.be';
				break;
		}
	}

	onClose(close: boolean) {
		if (close) {
			this.close.emit();
		}
	}

	onComplete(complete: boolean) {
		if (complete) {
			this.complete.emit();
		}
	}

	onCompleteModal(show: boolean) {
		this.showCompleteModalChange.emit(show);
	}

	onStepChange(step: number) {
		if (
			step > 0 ||
			this.subsidyType === 'hefboomproject' ||
			this.subsidyType === 'ivv' ||
			this.subsidyType === 'ivv_sto' ||
			this.subsidyType === 'ivv_sk'
		) {
			this.currentStepChange.emit(step);
		}
	}
}
