import { Subsidy } from '../../../services/subidies/subsidy.model';
import { RelanceRole } from '../../../models/relance-role.model';
import { FinanceDeminimi } from '../../../models/finance-deminimi.model';
import { Contact } from '../../../core/services/user/contact.model';
import { TripleAnwer } from '../../../models/triple-answer.enum';

export class SkSubsidy extends Subsidy {
	applicationContactRelanceRole?: RelanceRole;
	financeDeminimis?: FinanceDeminimi[];
	receivedDeminimis: TripleAnwer;
	responsibleForOrganizationContact: Contact;

	constructor(obj: any = {}) {
		super(obj);

		this.applicationContactRelanceRole = obj.applicationContactRelanceRole;
		this.financeDeminimis = obj.financeDeminimis ? obj.financeDeminimis.map((fd: any) => new FinanceDeminimi(fd)) : [];
		this.receivedDeminimis = obj.receivedDeminimis;
		this.responsibleForOrganizationContact = new Contact(obj.responsibleForOrganizationContact);
	}
}
