import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'tvsub-stepper',
	templateUrl: './stepper.component.html',
	styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {
	@Input()
	currentStep: number;
	@Input()
	disable: boolean = false;
	@Input()
	steps: string[];
	@Output()
	step: EventEmitter<number> = new EventEmitter();

	goToStep(index: number) {
		if (index < this.currentStep && !this.disable) {
			this.step.emit(index);
		}
	}
}
