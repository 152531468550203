import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'tvcoreFilter',
})
export class FilterPipe implements PipeTransform {
	transform(list: any[], propName: string, value: any): any[] {
		return list.filter((obj: any) => obj[propName] === value);
	}
}
