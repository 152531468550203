import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'tvcoreNumber',
})
export class NumberPipe implements PipeTransform {
	transform(value: number, defaultValue: string = '0', decimals?: number): string {
		if (value !== 0 && (!value || isNaN(value))) {
			return defaultValue;
		}

		const amount = (+value).toLocaleString('nl-BE').replace(/\./g, ' ');

		if (decimals) {
			const index = amount.indexOf(',');

			if (index > 0) {
				return amount.substring(0, index + 1 + decimals);
			}
		}

		return amount;
	}
}
