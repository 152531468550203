import { UntypedFormGroup } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { DestroyableComponent } from './destroyable.component';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Directive()
export abstract class FormComponent extends DestroyableComponent {
	@Input()
	forceErrors: boolean = false;

	form: UntypedFormGroup;

	private _showErrors: boolean = false;

	get showErrors(): boolean {
		return this.forceErrors || this._showErrors;
	}

	set showErrors(value: boolean) {
		this._showErrors = value;
	}

	controlHasError(controlName: string, showOnDirty: boolean = false): boolean {
		const control = this.form.get(controlName);

		if (control) {
			if (this.showErrors) {
				return !!control.errors;
			} else if (showOnDirty) {
				return control.dirty && !!control.errors;
			}
		}

		return false;
	}

	controlIsFloating(controlName: string): boolean {
		const control = this.form.get(controlName);

		if (control) {
			return control.value !== null && control.value !== '';
		}

		return false;
	}

	isFormValid(valid: boolean = true, dontTriggerInvalidForm: boolean = false): boolean {
		this.resetInvalidForm();

		this.showErrors = true;

		if (valid && this.form.valid) {
			return true;
		}

		if (!dontTriggerInvalidForm) {
			this.onInvalidForm();
		}

		return false;
	}

	protected controlShouldTriggerValidation(controlName: string, shouldTriggerControlName: string, form: UntypedFormGroup = this.form) {
		form.get(controlName)!
			.valueChanges.pipe(debounceTime(300), takeUntil(this.destroyed$))
			.subscribe(() => form.get(shouldTriggerControlName)!.updateValueAndValidity());
	}

	protected abstract onInvalidForm(): void;

	protected abstract resetInvalidForm(): void;
}
