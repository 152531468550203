import { Subsidy } from '../../../services/subidies/subsidy.model';
import { Contact } from '../../../core/services/user/contact.model';
import { ProjectLocation } from '../../../services/project-location/project-location.model';
import { SubsidySubtype } from './subsidy-subtype.enum';
import { Beneficiary } from '../../../services/beneficiary/beneficiary.model';
import { ProjectPhase } from '../../../services/project-phases/project-phase.model';
import { RelanceFocusArea } from './relance-focus-area.enum';
import { getDate } from '../../../core/utils/date-checker.util';
import { FinanceDeminimi } from '../../../models/finance-deminimi.model';
import { TripleAnwer } from '../../../models/triple-answer.enum';
import { RelanceRole } from '../../../models/relance-role.model';
import { FinanceRelance } from '../../../services/finance-relance/finance-relance.model';
import { CongressVenue } from './congress-venue.model';

export class RelanceSubsidy extends Subsidy {
	applicationContactRelanceRole?: RelanceRole;
	beneficiaries?: Beneficiary[];
	htmlDescriptions?: RelanceDescriptions | null;
	environmentalPermitRequired: TripleAnwer;
	eventPeriodEndDate: Date | null;
	eventPeriodStartDate: Date | null;
	financeDeminimis?: FinanceDeminimi[];
	financeRelance: FinanceRelance;
	motivationStateAidSchema: string;
	projectLaunchDate: Date | null;
	projectLocations?: ProjectLocation[];
	projectLocationDescription: string;
	projectTitle: string;
	receivedDeminimis: TripleAnwer;
	responsibleForOrganizationContact: Contact;
	subtype: SubsidySubtype;
	subsidyFocusAreas: RelanceFocusArea[];
	venue?: CongressVenue;

	constructor(obj: any = {}) {
		super(obj);

		this.applicationContactRelanceRole = obj.applicationContactRelanceRole;
		this.beneficiaries = obj.beneficiaries?.length ? obj.beneficiaries.map((b: any) => new Beneficiary(b)) : [];
		this.htmlDescriptions = obj.htmlDescriptions ? new RelanceDescriptions(obj.htmlDescriptions) : null;
		this.environmentalPermitRequired = obj.environmentalPermitRequired;
		this.eventPeriodEndDate = getDate(obj.eventPeriodEndDate);
		this.eventPeriodStartDate = getDate(obj.eventPeriodStartDate);
		this.financeDeminimis = obj.financeDeminimis ? obj.financeDeminimis.map((fd: any) => new FinanceDeminimi(fd)) : [];
		this.financeRelance = new FinanceRelance(obj.financeRelance);
		this.motivationStateAidSchema = obj.motivationStateAidSchema;
		this.projectLaunchDate = getDate(obj.projectLaunchDate);
		this.projectLocationDescription = obj.projectLocationDescription;
		this.projectTitle = obj.projectTitle;
		this.receivedDeminimis = obj.receivedDeminimis;
		this.responsibleForOrganizationContact = new Contact(obj.responsibleForOrganizationContact);
		this.subtype = obj.subtype;
		this.subsidyFocusAreas = obj.subsidyFocusAreas || [];

		if (this.subtype !== SubsidySubtype.DIGITAL_TRANSFORMATION_CONGRESSVENUES) {
			this.projectLocations = obj.projectLocations?.length ? obj.projectLocations.map((l: any) => new ProjectLocation(l)) : [new ProjectLocation()];
		} else {
			this.venue = new CongressVenue(obj.venue);
		}

		if (this.projectPhases!.length === 0) {
			this.projectPhases!.push(new ProjectPhase());
		}
	}
}

class RelanceDescriptions {
	ACCESSIBILITY: string;
	AUTHENTIC_EXPERIENCE: string;
	CRITERIA: string;
	FAMILYFRIENDLY: string;
	HOSPITABLE_CUSTOMER_ORIENTED_PROJECT: string;
	INNOVATIVE_CREATIVE_PROJECT: string;
	MOTIVATION_CONSCIOUS_TRAVEL: string;
	MOTIVATION_DIGITIZATION: string;
	MOTIVATION_GREENING: string;
	MOTIVATION_REASON_TO_COME: string;
	MOTIVATION_STORYLINE: string;
	MOTIVATION_SUSTAINABILITY: string;
	PLAN_OF_ACTION: string;
	PROJECT: string;

	DIGITAL_COMFORT: string;
	CUSTOMER_JOURNEY: string;
	EFFICIENCY: string;
	EXPERIENCE: string;
	INTERNATIONAL_POSITIONING: string;
	INTERNATIONAL_CONGRESSES: string;
	INTERNATIONAL_MULTILINGUAL_PROMOTION: string;

	constructor(obj: any = {}) {
		this.ACCESSIBILITY = obj.ACCESSIBILITY || '';
		this.AUTHENTIC_EXPERIENCE = obj.AUTHENTIC_EXPERIENCE || '';
		this.CRITERIA = obj.CRITERIA || '';
		this.FAMILYFRIENDLY = obj.FAMILYFRIENDLY || '';
		this.HOSPITABLE_CUSTOMER_ORIENTED_PROJECT = obj.HOSPITABLE_CUSTOMER_ORIENTED_PROJECT || '';
		this.INNOVATIVE_CREATIVE_PROJECT = obj.INNOVATIVE_CREATIVE_PROJECT || '';
		this.MOTIVATION_CONSCIOUS_TRAVEL = obj.MOTIVATION_CONSCIOUS_TRAVEL || '';
		this.MOTIVATION_DIGITIZATION = obj.MOTIVATION_DIGITIZATION || '';
		this.MOTIVATION_GREENING = obj.MOTIVATION_GREENING || '';
		this.MOTIVATION_REASON_TO_COME = obj.MOTIVATION_REASON_TO_COME || '';
		this.MOTIVATION_STORYLINE = obj.MOTIVATION_STORYLINE || '';
		this.MOTIVATION_SUSTAINABILITY = obj.MOTIVATION_SUSTAINABILITY || '';
		this.PLAN_OF_ACTION = obj.PLAN_OF_ACTION || '';
		this.PROJECT = obj.PROJECT || '';

		this.DIGITAL_COMFORT = obj.DIGITAL_COMFORT || '';
		this.CUSTOMER_JOURNEY = obj.CUSTOMER_JOURNEY || '';
		this.EFFICIENCY = obj.EFFICIENCY || '';
		this.EXPERIENCE = obj.EXPERIENCE || '';
		this.INTERNATIONAL_POSITIONING = obj.INTERNATIONAL_POSITIONING || '';
		this.INTERNATIONAL_CONGRESSES = obj.INTERNATIONAL_CONGRESSES || '';
		this.INTERNATIONAL_MULTILINGUAL_PROMOTION = obj.INTERNATIONAL_MULTILINGUAL_PROMOTION || '';
	}
}
