<form [formGroup]="innerForm">
	<tvcore-input (click)="overlayVisible = true" [label]="label" [showErrors]="showErrors" name="search"></tvcore-input>

	<div (click)="clear()" *ngIf="hasValue" [class.clear-icon--invalid]="isInvalid" class="clear-icon">
		<span class="icon-close"></span>
	</div>

	<div [class.c-overlay--visible]="overlayVisible" class="c-overlay">
		<div (click)="selectOption(option)" *ngFor="let option of visibleOptions" class="c-overlay__option">
			<ng-container *ngIf="optionTemplate" [ngTemplateOutletContext]="{ $implicit: option }"
										[ngTemplateOutlet]="optionTemplate"></ng-container>
			<ng-container *ngIf="!optionTemplate">{{ option }}</ng-container>
		</div>
	</div>
</form>

<ng-content></ng-content>
