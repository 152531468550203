<div *ngIf="!(online$ | async)" class="no-connection">
	<main>
		<section class="p-landing-page" [style.background-image]="'url(' + '/assets/img/landing-page-bg.jpg' + ')'">
			<h1 class="p-landing-page__title">
				Toerisme Vlaanderen
				<span class="u-text--uppercase">Subsidieaanvragen</span>
			</h1>

			<div class="p-landing-page__login anim-reveal-page">
				<div class="u-text--xlight u-text--sm">
					<strong>Subsidieaanvragen is momenteel niet bereikbaar.</strong>
					<p>Mogelijke oorzaken zijn onderhoudswerken aan de applicatie of een probleem met je internetverbinding.</p>
				</div>
			</div>
		</section>
	</main>
</div>
