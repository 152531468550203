import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
	constructor(private sessionService: SessionService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const doesntExpandsSession = req.url.indexOf('/amiup') >= 0;
		const keepSessionRequest = req.url.indexOf('/user') >= 0;
		const logout = req.url.indexOf('/logout') >= 0;

		if (!doesntExpandsSession && !keepSessionRequest && !logout) {
			this.sessionService.reset();
		}

		if (!doesntExpandsSession && !logout) {
			this.sessionService.start();
		}

		return next.handle(req);
	}
}
