import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { concat, interval, Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil, timeout } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class OnlineService {
	constructor(private http: HttpClient) {}

	checkOnline(until: any): Observable<boolean> {
		return concat(
			of(true),
			interval(5000).pipe(
				takeUntil(until),
				switchMap(() => this.amiup())
			)
		);
	}

	private amiup(): Observable<boolean> {
		return this.http.get(`/api10/amiup`).pipe(
			timeout(5000),
			map(() => true),
			catchError(() => of(false))
		);
	}
}
