import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { getQueryParamFromUrl } from '../utils/query-param-from-url.util';
import { ToastService } from '../services/toast/toast.service';
import { SessionService } from '../../services/session/session.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
	constructor(private router: Router, private sessionService: SessionService, private toastService: ToastService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((res: HttpErrorResponse) => {
				if (
					res.status === 401 &&
					res.error?.statusCode !== 'SUBSIDY_NOT_PARTNER' &&
					res.error?.statusCode !== 'SUBSIDY_CHARTER_NOT_SIGNED' &&
					res.error?.statusCode !== 'SUBSIDY_NOT_APPLICABLE'
				) {
					const queryParams: any = {
						UUID: getQueryParamFromUrl('UUID'),
					};

					this.router.navigate(['/', 'auth'], {
						queryParams,
					});

					if (req.url.indexOf('/users') < 0) {
						setTimeout(() => {
							this.toastService.clearToast();
							this.toastService.clearToast(true);
							this.sessionService.stop();
							this.router.navigate(['/', 'auth', 'logout'], { queryParams: { session: 'expired' } });
						}, 100);
					}
				}

				return throwError(res);
			})
		);
	}
}
