import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProjectLocation } from './project-location.model';
import { SubsidyType } from '../../models/subsidy-type.type';
import { City } from './city.model';

@Injectable({
	providedIn: 'root',
})
export class ProjectLocationsService {
	private _cities: BehaviorSubject<City[]> = new BehaviorSubject<City[]>([]);
	private _loadingCities: boolean = false;

	constructor(private http: HttpClient) {}

	get cities$(): Observable<City[]> {
		return this._cities.asObservable();
	}

	createLocation(subsidyType: SubsidyType, subsidyId: number, pl: ProjectLocation): Observable<any> {
		return this.http.post(`/api12/${subsidyType}/subsidy/${subsidyId}/projectlocation`, pl);
	}

	deleteLocation(subsidyType: SubsidyType, subsidyId: number, id: number): Observable<any> {
		return this.http.request('delete', `/api12/${subsidyType}/subsidy/${subsidyId}/projectlocation/${id}`, { body: {} });
	}

	updateLocation(subsidyType: SubsidyType, subsidyId: number, id: number, pl: ProjectLocation): Observable<any> {
		return this.http.post(`/api12/${subsidyType}/subsidy/${subsidyId}/projectlocation/${id}`, pl);
	}

	initCities() {
		if (this._cities.value.length <= 0 && !this._loadingCities) {
			this._loadingCities = true;

			this.getCities().subscribe((cities) => this._cities.next(cities.sort((a, b) => a.city.localeCompare(b.city))));
		}
	}

	private getCities(): Observable<City[]> {
		return this.http.get<City[]>('/api12/city');
	}
}
