import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { User } from './user.model';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private _user$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

	get user$(): Observable<User | null> {
		return this._user$.asObservable();
	}

	get user(): User | null {
		const u = this._user$.value;

		if (u) {
			return {
				...u,
			};
		}

		return null;
	}

	constructor(private http: HttpClient) {}

	initUser(): Observable<User> {
		if (this._user$.value) {
			return of(this._user$.value);
		}

		return this.http.get<User>('/api12/users').pipe(tap((user) => this._user$.next(user)));
	}

	logout(): Observable<void> {
		const logoutUrl = `/logout?callbackurl=${document.location.origin}`;
		return this.http.get<void>(logoutUrl).pipe(tap(() => this._user$.next(null)));
	}

	updateUser(uitId: string, user: User): Observable<User> {
		return this.http.post<User>(`/api12/users/${uitId}`, user).pipe(tap((u) => this._user$.next(u)));
	}
}
