import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ContextInterceptor } from './interceptors/context.interceptor';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { HeaderComponent } from './components/header/header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { FilterPipe } from './pipes/filter.pipe';
import { NumberPipe } from './pipes/number.pipe';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { FormControlErrorComponent } from './components/form-control-error/form-control-error.component';
import { InputComponent } from './components/input/input.component';
import { NormalizedAddressComponent } from './components/normalized-address/normalized-address.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { SelectComponent } from './components/select/select.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { ModalComponent } from './components/modal/modal.component';
import { ToastComponent } from './components/toast/toast.component';
import { LoaderComponent } from './components/loader/loader.component';
import { AlertComponent } from './components/alert/alert.component';
import { DialogToastComponent } from './components/dialog-toast/dialog-toast.component';
import { RichTextEditorComponent } from './components/rich-text-editor/rich-text-editor.component';
import { WizardDialogComponent } from './components/wizard-dialog/wizard-dialog.component';
import { UnitInputComponent } from './components/unit-input/unit-input.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SessionInterceptor } from '../services/session/session.interceptor';
import { DpDatePickerModule } from 'ng2-date-picker';
import 'dayjs/locale/nl-be';
import * as dayjs from 'dayjs';
import { PricePipe } from './pipes/price.pipe';
import { SearchSelectComponent } from './components/search-select/search-select.component';

dayjs.locale('nl-be');

const components = [
	HeaderComponent,
	FooterComponent,
	CheckboxComponent,
	FormControlErrorComponent,
	InputComponent,
	NormalizedAddressComponent,
	DatepickerComponent,
	SelectComponent,
	SearchSelectComponent,
	TooltipComponent,
	ModalComponent,
	ToastComponent,
	LoaderComponent,
	AlertComponent,
	DialogToastComponent,
	RichTextEditorComponent,
	WizardDialogComponent,
	UnitInputComponent,
];

const pipes = [FilterPipe, NumberPipe, PricePipe];

const modules = [CommonModule, HttpClientModule, ReactiveFormsModule, RouterModule, CKEditorModule, DpDatePickerModule];

@NgModule({
	declarations: [...components, ...pipes],
	imports: [...modules],
	exports: [...components, ...pipes, ...modules],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ContextInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UnauthorizedInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: SessionInterceptor,
			multi: true,
		},
	],
})
export class CoreModule {}
