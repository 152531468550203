import { Component, EventEmitter, Input, Output } from '@angular/core';
import { INFORMATION_TYPE_TRANSLATIONS } from '../../core/services/products/information-type.enum';
import { Product } from '../../core/services/products/product.model';

@Component({
	selector: 'tvsub-product-select-item',
	templateUrl: './product-select-item.component.html',
	styleUrls: ['./product-select-item.component.scss'],
})
export class ProductSelectItemComponent {
	@Input()
	isSelected: boolean;
	@Input()
	product: Product;
	@Input()
	text: string;
	@Output()
	select: EventEmitter<Product> = new EventEmitter();

	INFORMATION_TYPE_TRANSLATIONS = INFORMATION_TYPE_TRANSLATIONS;

	doSelect() {
		this.select.emit(this.product);
	}
}
