import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
	selector: 'tvcore-wizard-dialog',
	templateUrl: './wizard-dialog.component.html',
	styleUrls: ['./wizard-dialog.component.scss'],
})
export class WizardDialogComponent implements OnInit, OnDestroy {
	@Input()
	title?: string;
	@Input()
	fixed: boolean = false;
	@Input()
	flexibleHeight: boolean = false;
	@Output()
	close: EventEmitter<void> = new EventEmitter();

	ngOnInit() {
		// document.body.classList.add('has-overlay');
	}

	onClose() {
		this.close.emit();
	}

	ngOnDestroy() {
		// document.body.classList.remove('has-overlay');
	}
}
