import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Toast } from '../../services/toast/toast.model';
import { ToastService } from '../../services/toast/toast.service';

@Component({
	selector: 'tvcore-dialog-toast',
	templateUrl: './dialog-toast.component.html',
	styleUrls: ['./dialog-toast.component.scss'],
})
export class DialogToastComponent implements OnInit, OnDestroy {
	toast$: Observable<Toast | null>;

	constructor(private toastService: ToastService) {}

	ngOnInit() {
		this.toast$ = this.toastService.dialogToast$;
	}

	clearToast() {
		this.toastService.clearToast(true);
	}

	ngOnDestroy() {
		this.clearToast();
	}
}
