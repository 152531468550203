import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Address } from './address.model';

@Injectable({
	providedIn: 'root',
})
export class AddressesService {
	constructor(private http: HttpClient) {}

	findAddress(address: Address): Observable<Address[]> {
		const params = {
			street: address.street || '',
			postalCode: address.postalCode || '',
			cityName: address.cityName || '',
		};

		return this.http.get<Address[]>(`/api10/address/suggestion`, {
			params,
		});
	}

	getAsText(address: Address): string {
		let addressText = '';

		if (address.street) {
			addressText += address.street + ' ';
		}
		if (address.number) {
			addressText += address.number;
		}
		if (addressText.length && (address.postalCode || address.cityName)) {
			addressText += ', ';
		}
		if (address.postalCode) {
			addressText += address.postalCode + ' ';
		}
		if (address.cityName) {
			addressText += address.cityName;
		}

		return addressText;
	}

	isMinimal(address: Address): boolean {
		return !!(address.street || address.postalCode || address.cityName);
	}
}
