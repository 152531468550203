<div class="u-attachments-card" [class.u-attachments-card--error]="showError">
	<div class="u-attachments-card__content">
		<div class="u-attachments-card__title">
			<div class="c-round u-m-right--xs">
				<img src="/assets/icons/icon-sources/upload.svg" alt="Upload" />
			</div>
			<h6>{{ title }}</h6>
		</div>

		<ng-content></ng-content>

		<button class="c-btn c-btn--highlight u-m-top--md" (click)="selectFile()">Bestand opladen</button>
		<input [id]="'upload-button-' + type" #uploadButton type="file" multiple (change)="handleFileInput($event.target.files)" />

		<ng-container *ngIf="!disableSkip">
			<tvcore-checkbox *ngIf="skipText" class="u-m-top--sm" [control]="form.get(type)" [name]="'skip-' + type">{{ skipText }}</tvcore-checkbox>
			<tvcore-checkbox *ngIf="skipTextAlt" class="u-m-top--sm" [control]="form.get(type + 'Alt')" [name]="'skip-alt-' + type">{{
				skipTextAlt
			}}</tvcore-checkbox>
		</ng-container>

		<p *ngIf="showError" class="u-text--error u-m-top--sm u-text--sm">
			<ng-container *ngIf="errorText; else defaultError"> <span class="icon-warning-circle u-text--xlg"></span> {{ errorText }} </ng-container>
			<ng-template #defaultError>
				<span class="icon-warning-circle u-text--xlg"></span> Gelieve minstens 1 bewijsstuk op te laden<span *ngIf="skipText">
					of de checkbox aan te vinken</span
				>.
			</ng-template>
		</p>
	</div>

	<div class="u-attachments-card__items">
		<h6>Bijlagen</h6>

		<div class="u-attachments-card__list">
			<ng-container *ngFor="let a of subsidy.attachments | tvcoreFilter: 'type':type; let i = index">
				<div *ngIf="viewAll || i < 5" class="u-attachments-card__item">
					<a [href]="'/api12/' + subsidyType + '/' + api + '/' + subsidy.id + '/attachment/' + a.id" target="_blank" [download]="a.filename">{{
						a.filename
					}}</a>
					<p (click)="deleteAttachment(a)">
						<span>verwijderen</span>
						<span class="icon-trash"></span>
					</p>
				</div>

				<div *ngIf="!viewAll && i === 5" class="u-attachments-card__item" (click)="viewAll = true">
					<button>Meer tonen</button> <span class="icon-angle-down"></span>
				</div>
			</ng-container>
		</div>
	</div>
</div>
