import { Address } from '../../core/services/addresses/address.model';
import { LocationType } from './location-type.enum';
import { Province } from './province.enum';
import { Region } from './region.enum';

export class ProjectLocation {
	id?: number;
	locationType: LocationType | '';
	address?: Address;
	city?: number | null;
	province?: Province | '';
	region?: Region | '';

	constructor(obj: any = {}) {
		this.id = obj.id;
		this.locationType = obj.locationType || '';
		this.address = new Address(obj.address);
		this.city = obj.city ? obj.city.id || obj.city : null;
		this.province = obj.province || '';
		this.region = obj.region || '';
	}
}
