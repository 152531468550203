export class Address {
	id: number;
	box: string;
	cityId?: number;
	cityName: string;
	name: string;
	number: string;
	postalCode: string;
	province: string;
	street: string;

	countryIsoCode: string;
	foreignCity: string;
	foreignPostalCode: string;
	foreignState: string;

	constructor(obj: any = {}) {
		this.id = obj.id;
		this.box = obj.box;
		this.cityId = obj.cityId;
		this.cityName = obj.cityName;
		this.name = obj.name;
		this.number = obj.number;
		this.postalCode = obj.postalCode;
		this.province = obj.province;
		this.street = obj.street;

		this.countryIsoCode = obj.countryIsoCode;
		this.foreignCity = obj.foreignCity;
		this.foreignPostalCode = obj.foreignPostalCode;
		this.foreignState = obj.foreignState;
	}
}
