import { FinanceRelanceRecordSection } from './finance-relance-record-section.enum';
import { FinanceRelanceRecordType } from './finance-relance-record-type.enum';

export class FinanceRelanceRecord {
	id?: number;
	amount: number;
	description: string;
	section: FinanceRelanceRecordSection;
	type: FinanceRelanceRecordType;

	constructor(obj: any = {}) {
		this.id = obj.id;
		this.amount = obj.amount;
		this.description = obj.description;
		this.section = obj.section;
		this.type = obj.type;
	}
}
