import { Address } from '../../../core/services/addresses/address.model';
import { Contact } from '../../../core/services/user/contact.model';

export class CongressVenue {
	id: number;
	name: string;
	website: string;
	address: Address;
	venueContact: Contact;
	mininumCapacityPlenaryRooms: number;
	mininumCapacityBreakoutRooms: number;

	constructor(obj: any = {}) {
		this.id = obj.id;
		this.name = obj.name || '';
		this.website = obj.website;
		this.address = new Address(obj.address);
		this.venueContact = new Contact(obj.venueContact);
		this.mininumCapacityPlenaryRooms = obj.mininumCapacityPlenaryRooms;
		this.mininumCapacityBreakoutRooms = obj.mininumCapacityBreakoutRooms;
	}
}
